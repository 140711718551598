<template>
	<v-row id="form" v-if="formShow">
		<loader :dialog="loading"></loader>
		<floating-alert :message="alertMessage" :show="alertShow" :type="alertType"></floating-alert>
		<!-- <otp :dialog="dialog"></otp> -->
		<v-col cols="12" sm="12">
			<v-form ref="form" v-model="valid" lazy-validation>
				<v-text-field v-model="name" :rules="nameRules" label="Nom et prénom" required outlined rounded
					dense></v-text-field>
				<v-row>
					<v-col cols="12" sm="6">
						<v-text-field v-model="cni" :rules="cniRules" label="CNI" required outlined hide-details=""
							rounded dense></v-text-field>
					</v-col>
					<v-col cols="12" sm="6">
						<v-text-field v-model="email" :rules="emailRules" label="Email" required outlined rounded dense
							hide-details=""></v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" sm="6">
						<v-text-field v-model="phone" :rules="phoneRules" label="Téléphone" required outlined rounded
							dense></v-text-field>
					</v-col>
					<v-col cols="12" sm="6">
						<v-select :rules="roleRules" label="Types" rounded outlined dense :items="roles"
							v-model="role"></v-select>
					</v-col>
				</v-row>

				<v-text-field v-model="password" :rules="passwordRules" label="Mot de passe" required rounded outlined
					dense></v-text-field>
				<v-alert type="error" dense dismissible v-if="error">{{
					errorMessage
				}}</v-alert>
				<v-btn :disabled="!valid" color="primary" @click="validate" rounded outlined style="width: 100%">
					S'enregistrer
				</v-btn>
			</v-form>
		</v-col>
		<v-col>
			<v-divider></v-divider>
		</v-col>
		<p style="font-size: 15px">
			Vous avez déjà un compte?
			<v-btn color="primary" x-small text @click="removeForm()">Connectez-vous</v-btn>
		</p>
	</v-row>
</template>

<script>
import service from "@/services";
// import Otp from "../Otp.vue";
import Loader from "../Loader.vue";
import FloatingAlert from "@/components/FloatingAlert.vue";
export default {
	components: { Loader, FloatingAlert },
	props: ["formShow"],
	data: () => ({
		loading: false,
		valid: true,
		role: "",
		error: false,
		errorMessage: "",
		roleRules: [(v) => !!v || "Sélectionnez le role"],
		roles: ["SECRETAIRE", "COACH"],
		name: "",
		nameRules: [(v) => !!v || "Entrez votre nom et prénom"],
		phone: "",
		phoneRules: [
			(v) => !!v || "Entrez un numéro de téléphone",
			(v) =>
				(v && v.length <= 9) ||
				"Le numéro de téléphone doit avoir 9 chiffres",
		],
		password: "",
		passwordRules: [
			(v) => !!v || "Entrez votre mot de passe",
			(v) =>
				(v && v.length >= 8) ||
				"Le mot de passe doit avoir au moins 8 caractères",
		],
		email: "",
		emailRules: [(v) => !!v || "Entrez votre Email"],
		cni: "",
		cniRules: [(v) => !!v || "Entrez votre numéro de CNI"],
		dialog: false,
		alertMessage: "",
		alertShow: false,
		alertType: "",
	}),

	methods: {
		manageAlert(display, type, message) {
			this.alertShow = display;
			this.alertType = type;
			this.alertMessage = message;
		},
		validate() {
			this.error = false;
			if (this.$refs.form.validate()) {
				this.loading = true;
				service
					.register({
						fullName: this.name,
						role: this.role,
						phone: this.phone,
						password: this.password,
						email: this.email,
						cni: this.cni,
					})
					.then((results) => {
						this.loading = false;
						this.manageAlert(true, "success", "Compte créé");
						this.removeForm();
						// this.dialog = true;
					})
					.catch((err) => {
						console.log(err);
						this.error = true;
						this.errorMessage = err.message;
						this.loading = false;
					});
			}
		},
		removeForm() {
			this.$emit("changeForm");
		},
	},
};
</script>

<style scoped>
v-form-item {
	margin-top: 0%;
}

#form {
	margin-top: 5%;
	margin-left: 15%;
	margin-right: 15%;
	font-family: Montserrat;
}
</style>
