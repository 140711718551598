<template>
	<v-app>
		<v-main>
			<router-view />
		</v-main>
	</v-app>
</template>

<script>
export default {
	name: "App",

	data: () => ({
		//
	}),
};
</script>

<style>
* {
	font-family: Raleway;
}
#app {
	background-color: rgba(229, 35, 34, 0.1);
}
@font-face {
	font-family: Megatron;
	src: url("../font/Megatron.otf");
}

@font-face {
	font-family: Raleway;
	src: url("../font/Raleway-Medium.ttf");
}
</style>
