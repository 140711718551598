<template>
  <div>
    <loader :dialog="loading"></loader>
    <v-data-table :headers="headers" :items="clients" class="elevation-10 pa-2 rounded-xl" :loading="loadingTable"
      loading-text="Chargement en cours..." :search="search" :footer-props="{
        'items-per-page-all-text': 'Tout',
        'items-per-page-text': 'Lignes par page',
      }">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Liste des clients</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Rechercher" hide-details></v-text-field>
          <!-- CUSTOMER DETAILS -->
          <v-dialog v-model="customerDetailsDialog" max-width="500px">
            <customer-details :customer="client"
              @close-customer-details-dialog="customerDetailsDialog = false"></customer-details>
          </v-dialog>
          <!-- FOODS LIST -->
          <v-dialog v-model="foodDialog" max-width="500px">
            <v-card>
              <v-card-title>Liste des repas
                <v-spacer></v-spacer>
                <v-btn icon @click="foodDialog = false"><v-icon>mdi-close-circle</v-icon></v-btn>
              </v-card-title>
              <v-card-text>
                <food :foods="food"></food>
              </v-card-text>
            </v-card>
          </v-dialog>

          <!-- PROTOCOLS LIST -->
          <v-dialog scrollable v-model="protocolDialog" max-width="500px">
            <protocoles @close-protocol-dialog="protocolDialog = false" :protocols="protocols"></protocoles>
          </v-dialog>

          <!-- SUBSCRIPTIONS LIST -->
          <v-dialog v-model="subscriptionDialog" max-width="500px">
            <v-card>
              <v-card-title>Abonnement
                <v-spacer></v-spacer>
                <v-btn icon @click="subscriptionDialog = false"><v-icon>mdi-close-circle</v-icon></v-btn>
              </v-card-title>
              <v-card-text>
                <subscriptions :client="subscription"></subscriptions>
              </v-card-text>
            </v-card>
          </v-dialog>

          <v-dialog v-model="rewardDialog" max-width="500px" persistent scrollable>
            <v-card flat>
              <v-card-title>Liste des récompenses</v-card-title>
              <v-card-text>
                <reward :rewards="rewards" :customerId="clientId" @close-reward-dialog="rewardDialog = false"></reward>
              </v-card-text>
              <v-card-actions>
                <v-btn color="error" text @click="rewardDialog = false">
                  Annuler
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" text> Enregistrer </v-btn>
              </v-card-actions>
            </v-card></v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Êtes-vous sûrs de vouloir supprimer?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="closeDelete">Annuler</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small @click="showProtocol(item._id)" class="mr-2" v-bind="attrs" v-on="on">
              mdi-dumbbell
            </v-icon>
          </template>
          <span>Protocoles</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small @click="showFoods(item)" class="mr-2" v-bind="attrs" v-on="on">
              mdi-food
            </v-icon>
          </template>
          <span>Repas</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small @click="showSubscription(item)" class="mr-2" v-bind="attrs" v-on="on">
              mdi-playlist-check
            </v-icon>
          </template>
          <span>Abonnement</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon @click="showReward(item._id)" small class="mr-2" v-bind="attrs" v-on="on">
              mdi-trophy
            </v-icon>
          </template>
          <span>Récompenses</span>
        </v-tooltip>
        <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Actualiser </v-btn>
      </template>
      <template v-slot:[`item.fullName`]="{ item }">
        <v-btn text @click="showCustomerDetails(item)">
          {{ item.fullName }}
        </v-btn>
      </template>
      <template v-slot:[`item.registered`]="{ item }">
        <v-chip :color="getColorRegistered(item.registered)" dark>
          {{
            item.registered === null ? "Non" : item.registered ? "Oui" : "Non"
          }}
        </v-chip>
      </template>
      <template v-slot:[`item.weight`]="{ item }">
        <v-chip :color="getColor(item.weight)" dark>
          {{ item.weight }}
        </v-chip>
      </template>
      <template v-slot:[`item.birthday`]="{ item }">
        <span>
          {{ moment().diff(item.birthday, "years") }}
        </span>
      </template>
      <template v-slot:[`item.subscriptionCreatedAt`]="{ item }">
        <span v-if="item.inPause"> <v-chip>En pause</v-chip> </span>
        <v-tooltip bottom v-else-if="item.subscription">
          <template v-slot:activator="{ on, attrs }">
            <v-progress-linear v-bind="attrs" v-on="on" :color="getProgressionColor(
              item.subscriptionCreatedAt,
              item.subscription.duration
            )
              " :value="100 -
                ((item.subscription.duration -
                  moment().diff(moment(item.subscriptionCreatedAt), 'days') -
                  1) /
                  item.subscription.duration) *
                100
                " height="7" rounded>
            </v-progress-linear>
          </template>
          <span v-if="
            item.subscription.duration >
            item.subscription.duration -
            moment().diff(moment(item.subscriptionCreatedAt), 'days') -
            1
          ">
            <v-icon dark>mdi-calendar</v-icon> :
            {{
              item.subscription.duration -
              moment().diff(moment(item.subscriptionCreatedAt), "days") -
              1
            }}
            jours restants <br />
            Type: {{ item.subscription.type }}
          </span>
          <span v-else> Non abonné </span>
        </v-tooltip>
      </template>
      <template v-slot:[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }} de
        {{ items.itemsLength }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment";
import service from "../services";
import Loader from "@/components/Loader.vue";
import Food from "@/components/Admin/Customers/Food.vue";
import Reward from "@/components/Admin/Customers/Reward.vue";
import Protocoles from "@/components/Admin/Customers/Protocoles.vue";
import Subscriptions from "@/components/Admin/Customers/Subscriptions.vue";
import CustomerDetails from "@/components/Admin/Customers/CustomerDetails.vue";
export default {
  components: {
    Food,
    Protocoles,
    Subscriptions,
    Loader,
    Reward,
    CustomerDetails,
  },
  data() {
    return {
      moment,
      rewardDialog: false,
      rewards: [],
      clientStatus: "",
      search: "",
      foodDialog: false,
      protocolDialog: false,
      subscriptionDialog: false,
      customerDetailsDialog: false,
      statuses: ["Bloqué", "Débloqué"],
      food: [],
      protocols: [],
      subscription: {},
      dialogDelete: false,
      loadingTable: false,
      loading: false,
      pagination: {},
      headers: [
        {
          text: "Noms",
          align: "start",
          sortable: false,
          value: "fullName",
        },
        { text: "Inscrit", value: "registered" },
        { text: "Age", value: "birthday" },
        { text: "Sexe", value: "sexe" },
        { text: "Poids en Kg", value: "weight" },
        { text: "Objectif", value: "objectif" },
        {
          text: "Abonnement",
          value: "subscriptionCreatedAt",
          sortable: false,
        },
        // { text: "Points", value: "points" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      clients: [],
      clientId: "",
      client: {},
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.loadingTable = true;
      service
        .getClients()
        .then((response) => {
          this.clients = response.data;
          this.loadingTable = false;
        })
        .catch((err) => {
          console.log(err);
          this.loadingTable = false;
        });
    },
    showReward(clientId) {
      this.loading = true;
      service
        .getRewards()
        .then((response) => {
          this.rewards = response.data;
          this.clientId = clientId;
          this.rewardDialog = true;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showCustomerDetails(client) {
      this.customerDetailsDialog = true;
      this.client = client;
    },
    showSubscription(client) {
      this.subscriptionDialog = true;
      this.subscription = client.subscription
        ? client
        : "Aucun abonnement attribué";
    },
    showFoods(client) {
      this.loading = true;
      service
        .getCustomerFood(client._id)
        .then((response) => {
          this.food = response.data;
          this.loading = false;
          this.foodDialog = true;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    showProtocol(clientId) {
      this.protocolDialog = true;
      this.loading = true;
      service
        .getProtocol(clientId)
        .then((results) => {
          this.protocols = results.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    getColor(weight) {
      if (weight < 100) return "green";
      else if (weight > 100) return "red";
      else return "orange";
    },
    getColorRegistered(registered) {
      if (registered === false) return "red";
      if (registered === null) return "red";
      else if (registered === true) return "green";
      else return "green";
    },
    getProgressionColor(subscriptionCreatedAt, duration) {
      let currentDate = moment();
      let subscriptionDate = moment(subscriptionCreatedAt);
      if (currentDate.diff(subscriptionDate, "days") < duration - 11) {
        return "primary";
      } else if (
        currentDate.diff(subscriptionDate, "days") > duration - 11 &&
        currentDate.diff(subscriptionDate, "days") < duration
      ) {
        return "yellow";
      } else {
        return "red";
      }
    },
    getStatusColor(status) {
      if (status == true) return "green";
      else return "red";
    },
    updateClientStatus(customerId) {
      this.loading = true;
      let status = this.clientStatus == "Bloqué" ? false : true;
      service
        .changeClientStatus(customerId, status)
        .then((results) => {
          console.log(results);
          this.loading = false;
          this.initialize();
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    deleteItem(item) {
      this.editedIndex = this.clients.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.clients.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>

<style></style>
