<template>
	<section>
		<loader :dialog="loaderDialog"></loader>
		<floating-alert
			:message="showAlertMessage"
			:show="showAlert"
			:type="showAlertColor"></floating-alert>
		<v-alert type="error" prominent v-if="accessDenied">
			<h5>Accès refusé</h5>
			Vous n'avez pas les autorisations pour avoir accès à ce contenu!
		</v-alert>
		<v-data-table
			:headers="headers"
			:items="exercises"
			v-model="selectedExercises"
			class="elevation-0 pa-2 rounded-xl"
			:single-select="false"
			show-select
			item-key="_id"
			:loading="loadingTable"
			loading-text="Chargement en cours..."
			:search="search"
			no-results-text="Aucun exercice correpondant"
			:footer-props="{
				'items-per-page-all-text': 'Tout',
				'items-per-page-text': 'Lignes par page',
			}">
			<template v-slot:top>
				<v-toolbar flat>
					<v-toolbar-title>Liste des exercices</v-toolbar-title>
					<v-divider class="mx-4" inset vertical></v-divider>
					<v-spacer></v-spacer>
					<v-text-field
						v-model="search"
						append-icon="mdi-magnify"
						label="Rechercher"
						hide-details></v-text-field>
					<v-spacer></v-spacer>
					<v-dialog v-model="dialog" max-width="500px" scrollable>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								color="red"
								dark
								class="mb-2 rounded-xl"
								v-bind="attrs"
								v-on="on"
								v-if="!accessDenied">
								<v-icon left>mdi-plus</v-icon> Nouveau
							</v-btn>
						</template>
						<v-card>
							<v-card-title>
								<span class="text-h5">{{ formTitle }}</span>
							</v-card-title>

							<v-card-text>
								<v-container>
									<v-row>
										<v-col cols="12" sm="6">
											<v-text-field
												v-model="editedItem.label"
												label="Nom de l'exercice"></v-text-field>
										</v-col>
										<v-col cols="12" sm="6">
											<v-text-field
												v-model="editedItem.videoLink"
												label="Lien de la vidéo"
												disabled></v-text-field>
										</v-col>
										<v-col cols="12" sm="6">
											Durée
											<vue-number-input
												v-model="editedItem.duration"
												center
												size="small"
												:min="0"
												controls></vue-number-input>
										</v-col>

										<v-col cols="12" sm="6">
											Nombre de séries
											<vue-number-input
												v-model="editedItem.series"
												center
												size="small"
												:min="0"
												controls></vue-number-input>
										</v-col>
										<v-col cols="12" sm="4">
											Temps d'exécution
											<vue-number-input
												:disabled="
													editedItem.repetition
												"
												v-model="
													editedItem.executionDuration
												"
												center
												:min="0"
												size="small"
												controls></vue-number-input>
										</v-col>
										<v-col cols="12" sm="4">
											Temps de récupération
											<vue-number-input
												v-model="
													editedItem.recoveryDuration
												"
												center
												:min="0"
												size="small"
												controls></vue-number-input>
										</v-col>
										<v-col cols="12" sm="4">
											Nombre de répétitions
											<vue-number-input
												v-model="editedItem.repetition"
												center
												size="small"
												controls
												:min="0"
												@change="
													handleRepetitionInput
												"></vue-number-input>
										</v-col>

										<v-col cols="12" sm="12">
											Description
											<VueEditor
												v-model="editedItem.description"
												:editorToolbar="
													customToolbar
												"></VueEditor>
										</v-col>
									</v-row>
								</v-container>
							</v-card-text>

							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="red darken-1" text @click="close">
									Annuler
								</v-btn>
								<v-btn color="blue darken-1" text @click="save">
									Enregistrer
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
					<v-dialog
						v-model="syncDialog"
						max-width="500px"
						scrollable
						v-if="selectedExercises.length > 0">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								color="primary"
								outlined
								rounded
								dark
								class="mb-2 ml-3"
								v-bind="attrs"
								v-on="on"
								@click="getProtocols">
								<v-icon>mdi-sync</v-icon>
								Synchroniser
							</v-btn>
						</template>
						<v-card>
							<v-card-title>
								<span class="text-h5"
									>Synchroniser au.x protocole.s</span
								>
							</v-card-title>

							<v-card-text>
								<v-autocomplete
									v-model="selectedProtocolId"
									:items="protocols"
									item-text="label"
									item-value="_id"
									label="Sélectionnez le.s protocole.s"></v-autocomplete>
								<div class="mt-3">
									Exercice.s sélectionné.s :
									<v-chip-group column>
										<v-chip
											v-for="selected in selectedExercises"
											:key="selected._id"
											class="red--text text--darken-3 font-weight-bold">
											{{ selected.label }}
										</v-chip>
									</v-chip-group>
								</div>
							</v-card-text>

							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn
									color="red darken-1"
									text
									@click="syncDialog = false">
									Annuler
								</v-btn>
								<v-btn
									color="blue darken-1"
									text
									@click="addSynchronization">
									Enregister
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>

					<v-dialog v-model="dialogDelete" max-width="500px">
						<v-card>
							<v-card-title class="text-h5"
								>Êtes-vous sûrs de vouloir
								supprimer?</v-card-title
							>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn
									color="red darken-1"
									text
									@click="dialogDelete = false"
									>Annuler</v-btn
								>
								<v-btn
									color="blue darken-1"
									text
									@click="deleteItemConfirm"
									>Confirmer</v-btn
								>
								<v-spacer></v-spacer>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</v-toolbar>
			</template>
			<template v-slot:[`item.description`]="{ item }">
				<span v-html="item.description"></span>
			</template>
			<template v-slot:[`item.actions`]="{ item }">
				<v-icon small class="mr-2" @click="editItem(item)">
					mdi-pencil
				</v-icon>
				<v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
			</template>
			<template v-slot:[`footer.page-text`]="items">
				{{ items.pageStart }} - {{ items.pageStop }} de
				{{ items.itemsLength }}
			</template>
			<template v-slot:no-data>
				<v-btn color="primary" @click="initialize"> Actualiser </v-btn>
			</template>
		</v-data-table>
	</section>
</template>

<script>
	import Loader from "@/components/Loader.vue";
	import FloatingAlert from "@/components/FloatingAlert.vue";
	import service from "@/services";
	export default {
		components: { Loader, FloatingAlert },
		data: () => ({
			search: "",
			accessDenied: false,
			showAlert: false,
			showAlertMessage: "",
			showAlertColor: "",
			selectedExercises: [],
			selectedProtocolId: "",
			syncDialog: false,
			dialog: false,
			dialogDelete: false,
			loaderDialog: false,
			loadingTable: false,
			exercises: [],
			protocols: [],
			headers: [
				{
					text: "Intitulé",
					value: "label",
				},
				{ text: "Description", value: "description" },
				{ text: "Durée", value: "duration", align: "center" },
				{
					text: "Temps d'exécution",
					value: "executionDuration",
					align: "center",
				},
				{
					text: "Temps de récupération",
					value: "recoveryDuration",
					align: "center",
				},
				{
					text: "Nbre de répétitions",
					value: "repetition",
					align: "center",
				},
				{ text: "Nbre de séries", value: "series", align: "center" },
				{ text: "Actions", value: "actions", sortable: false },
			],
			editedItem: {
				label: "",
				description: "",
				duration: 0,
				executionDuration: 0,
				recoveryDuration: 0,
				series: 0,
				repetition: 0,
				videoLink: "",
				imageLink: "",
				_id: "",
			},
			defaultItem: {
				label: "",
				description: "",
				duration: 0,
				executionDuration: 0,
				recoveryDuration: 0,
				series: 0,
				repetition: 0,
				videoLink: "",
				imageLink: "",
				_id: "",
			},
			customToolbar: [
				[{ header: [false, 1, 2, 3, 4, 5, 6] }],
				["bold", "italic", "underline"],
				[{ color: [] }],
				[{ list: "ordered" }, { list: "bullet" }],
			],
			editedIndex: -1,
		}),
		computed: {
			formTitle() {
				return this.editedIndex === -1
					? "Nouvel Exercice"
					: "Modification de l'exercice";
			},
		},
		watch: {
			dialog(val) {
				val || this.close();
			},
			dialogDelete(val) {
				val || this.closeDelete();
			},
		},
		created() {
			this.initialize();
		},
		methods: {
			handleRepetitionInput() {
				if (this.editedItem.repetition != 0) {
					this.editedItem.executionDuration = 0;
				}
			},
			initialize() {
				this.loadingTable = true;
				service
					.getExercises()
					.then((response) => {
						this.exercises = response.data;
						this.loadingTable = false;
					})
					.catch((err) => {
						console.log(err);
						this.accessDenied = err ? true : false;
						this.loadingTable = false;
					});
			},
			manageAlert(display, type, message) {
				this.showAlert = false;
				this.showAlert = display;
				this.showAlertColor = type;
				this.showAlertMessage = message;
			},
			save() {
				if (this.editedIndex > -1) {
					this.loaderDialog = true;
					service
						.updateExercise(this.editedItem._id, this.editedItem)
						.then((response) => {
							this.loaderDialog = false;
							this.close();
							this.manageAlert(true, "success", response.message);
						})
						.catch((err) => {
							console.log(err);
							this.loaderDialog = false;
							this.manageAlert(true, "error", err.message);
						});
				} else {
					this.loaderDialog = true;
					service
						.addExercise(this.editedItem)
						.then((response) => {
							this.loaderDialog = false;
							this.manageAlert(true, "success", response.message);
							this.close();
							this.initialize();
						})
						.catch((err) => {
							console.log(err);
							this.loaderDialog = false;
							this.manageAlert(true, "red", err.message);
						});
				}
			},
			getProtocols() {
				this.loaderDialog = true;
				service
					.getProtocols()
					.then((response) => {
						this.protocols = response.data.data;
						this.loaderDialog = false;
					})
					.catch((err) => {
						console.log(err);
						this.loaderDialog = false;
					});
			},
			addSynchronization() {
				this.loaderDialog = true;
				let exerciseIds = this.selectedExercises.map((exercise) => {
					return exercise._id;
				});
				service
					.syncExerciseWithProtocol(
						exerciseIds,
						this.selectedProtocolId
					)
					.then((results) => {
						this.selectedExercises = [];
						this.selectedProtocolId = "";
						this.loaderDialog = false;
						this.syncDialog = false;
						this.manageAlert(true, "success", results.message);
					})
					.catch((err) => {
						this.loading = false;
						this.manageAlert(true, "error", err.message);
					});
			},
			editItem(item) {
				this.editedIndex = this.exercises.indexOf(item);
				this.editedItem = Object.assign({}, item);
				this.dialog = true;
			},

			deleteItem(item) {
				this.editedIndex = this.exercises.indexOf(item);
				this.editedItem = Object.assign({}, item);
				this.dialogDelete = true;
			},

			deleteItemConfirm() {
				this.loaderDialog = true;
				service
					.deleteExercise(this.editedItem._id)
					.then((response) => {
						this.loaderDialog = false;
						this.manageAlert(true, "success", response.message);
						this.closeDelete();
					})
					.catch((err) => {
						this.loaderDialog = false;
						this.manageAlert(true, "red", err.message);
					});
			},

			close() {
				this.dialog = false;
				this.$nextTick(() => {
					this.editedItem = Object.assign({}, this.defaultItem);
					this.editedIndex = -1;
				});
			},

			closeDelete() {
				this.dialogDelete = false;
				this.$nextTick(() => {
					// this.editedItem = Object.assign({}, this.defaultItem);
					this.initialize();
					this.editedIndex = -1;
				});
			},
		},
	};
</script>

<style></style>
