import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Chartkick from "vue-chartkick";
import { Chart } from "chart.js";
import VueNumberInput from '@chenfengyuan/vue-number-input';
import { VueEditor } from "vue2-editor";

Vue.config.productionTip = false;
Vue.use(Chartkick.use(Chart));
Vue.component('vue-number-input', VueNumberInput);
Vue.component('VueEditor', VueEditor);

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount("#app");
