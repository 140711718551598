<template>
	<v-dialog v-model="dialog" persistent max-width="600px">
		<template v-slot:activator="{ on, attrs }">
			<v-btn text v-bind="attrs"
          v-on="on">
				<v-avatar size="36" class="mr-2">
					<v-icon>mdi-account-circle</v-icon>
				</v-avatar>
				Boby
			</v-btn>
		</template>
		<v-card>
			<v-card-title>
				<span class="text-h5">Profil utilisateur</span>
			</v-card-title>
			<v-card-text>
				<v-container>
					<v-row>
						<v-col cols="12" sm="12">
							<v-text-field
								label="Nom et prénom"
								prepend-inner-icon="mdi-account"
                                outlined
								dense rounded
							></v-text-field>
						</v-col>
						<v-col cols="12" sm="12">
							<v-text-field
								label="Numéro de téléphone"
								prepend-inner-icon="mdi-cellphone"
								outlined
								dense
								rounded
							></v-text-field>
						</v-col>
						<v-col cols="12" sm="12" md="6">
							<v-text-field
								label="CNI"
								prepend-inner-icon="mdi-clipboard-account"
								required
								rounded
								dense
								outlined
							></v-text-field>
						</v-col>
						<v-col cols="12" sm="12" md="6">
							<v-text-field
								label="Adresse"
								prepend-inner-icon="mdi-map"
								required
								dense
								rounded
								outlined
							></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-text-field
								label="Adresse email"
								prepend-inner-icon="mdi-at"
								required
								dense
								rounded
								outlined
							></v-text-field>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="error" text @click="dialog = false">
					Fermer
				</v-btn>
				<v-btn color="blue darken-1" text @click="dialog = false">
					Enregistrer
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	export default {
		data() {
			return {
				dialog: false,
			};
		},
	};
</script>

<style></style>
