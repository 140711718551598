<template>
	<v-dialog v-model="dialog" width="500">
		<template v-slot:activator="{ on, attrs }">
			<v-btn icon class="mr-4 black--text" v-bind="attrs" v-on="on">
				<v-icon>mdi-qrcode</v-icon>
			</v-btn>
		</template>

		<v-card class="pa-5">

			<v-card-text class="text-center">
				<img
						:src="qrCode"
						alt=""
						style="width: 70%"
					/>
			</v-card-text>

			<v-divider></v-divider>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="error" text @click="dialog = false">
					Fermer
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
    import QRCode from "qrcode";
	export default {
		props: ["userId"],
		data() {
			return {
				dialog: false,
			};
		},
        computed: {
			qrCode: function() {
				/* let enterpriseData = {
					enterpriseId: JSON.parse(localStorage.getItem("enterprise"))
						._id,
					name: JSON.parse(localStorage.getItem("enterprise")).name,
					price: parseInt(this.price),
					isEnterprise: true,
				}; */
				let global = this;
				var opts = {
					errorCorrectionLevel: "H",
					type: "image/jpeg",
					quality: 1,
					color: {
						dark: "#000000",
						light: "#FFFFFF",
					},
				};

				QRCode.toDataURL(
					JSON.stringify({presenceId: "62ff44b47cf4beff5023966a"}),
					opts,
					function (err, url) {
						if (err) throw err;

						global = url;
					}
				);
				
				return global;
			},
		},
	};
</script>

<style></style>
