<template>
  <section>
    <loader :dialog="loading"></loader>
    <floating-alert
      :message="alertMessage"
      :show="alertShow"
      :type="alertType"
    ></floating-alert>
    <v-data-table
      :headers="headers"
      :items="protocols"
      :single-expand="true"
      :expanded.sync="expanded"
      item-key="_id"
      show-expand
      v-model="selectedProtocols"
      :single-select="false"
      show-select
      class="elevation-10 pa-2 rounded-xl"
      :loading="loadingTable"
      loading-text="Chargement en cours..."
      :search="search"
      :footer-props="{
        'items-per-page-all-text': 'Tout',
        'items-per-page-text': 'Lignes par page',
      }"
    >
      <template v-slot:[`item.objectif`]="{ item }">
        {{ item.objective }}
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Liste des protocoles</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Rechercher"
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px" scrollable>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="red"
                dark
                class="mb-2 rounded-xl"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-plus</v-icon> Nouveau
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="editedItem.label"
                        label="Intitulé"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="editedItem.duration"
                        label="Durée (en semaine)"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        v-model="editedItem.objective"
                        :items="objective"
                        return-object
                        item-text="objectif"
                        label="Objectif"
                      ></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        v-model="editedItem.gender"
                        :items="gender"
                        return-object
                        item-text="sexe"
                        label="Sexe"
                      ></v-select>
                    </v-col>
                    <v-col cols="6">
                      <v-menu
                        v-model="startDateMenu"
                        :close-on-content-click="false"
                        :nudge-right="35"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedItem.startDate"
                            label="Date début"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          locale="Fr"
                          color="red"
                          v-model="editedItem.startDate"
                          @input="startDateMenu = false"
                        ></v-date-picker>
                      </v-menu>
                      <!-- <v-text-field v-model="editedItem.startDate" label="Date de début"></v-text-field> -->
                    </v-col>
                    <v-col cols="6">
                      <v-menu
                        v-model="endDateMenu"
                        :close-on-content-click="false"
                        :nudge-right="35"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedItem.endDate"
                            label="Date de fin"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          locale="Fr"
                          color="red"
                          v-model="editedItem.endDate"
                          @input="endDateMenu = false"
                        ></v-date-picker>
                      </v-menu>
                      <!-- <v-text-field v-model="editedItem.endDate" label="Date de fin"></v-text-field> -->
                    </v-col>
                    <v-col cols="12">
                      <label for="description">Description</label>
                      <VueEditor
                        v-model="editedItem.description"
                        :editorToolbar="customToolbar"
                      ></VueEditor>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="dialog = false">
                  Annuler
                </v-btn>
                <v-btn color="blue darken-1" text @click="save">
                  Enregister
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="attributionDialog"
            max-width="500px"
            scrollable
            v-if="selectedProtocols.length > 1"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                rounded
                outlined
                dark
                class="mb-2 ml-3"
                v-bind="attrs"
                v-on="on"
                @click="getClients"
              >
                <v-icon>mdi-account-arrow-left</v-icon>
                Attribuer
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Attribuer au client</span>
              </v-card-title>

              <v-card-text>
                <v-autocomplete
                  v-model="selectedClientId"
                  :items="clients"
                  item-text="fullName"
                  item-value="_id"
                  label="Sélectionnez le client"
                ></v-autocomplete>
                <div class="mt-3">
                  Protocole.s sélectionné.s :
                  <v-chip-group column>
                    <v-chip
                      v-for="selected in selectedProtocols"
                      :key="selected._id"
                      class="red--text text--darken-3 font-weight-bold"
                    >
                      {{ selected.label }}
                    </v-chip>
                  </v-chip-group>
                </div>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red darken-1"
                  text
                  @click="attributionDialog = false"
                >
                  Annuler
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="multiProtocolAttribution"
                >
                  Enregister
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Êtes-vous sûrs de vouloir supprimer?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="closeDelete"
                  >Annuler</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Confirmer</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogUsers" max-width="500px" scrollable>
            <client-list
              :protocolId="protocolId"
              @close-client-dialog="dialogUsers = false"
              @successful-attribution="
                manageAlert(true, 'success', 'Attribution effectuée');
                dialogUsers = false;
              "
            ></client-list>
          </v-dialog>
          <v-dialog v-model="dialogExercise" max-width="500px" scrollable>
            <v-card>
              <v-card-title>
                <span class="text-h5">Information sur l'exercice</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        disabled
                        v-model="editedItem.label"
                        label="Nom de l'exercice"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedItem.videoLink"
                        label="Lien de la vidéo"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      Durée
                      <vue-number-input
                        disabled
                        v-model="editedItem.duration"
                        center
                        size="small"
                        controls
                      ></vue-number-input>
                    </v-col>

                    <v-col cols="12" sm="6">
                      Nombre de séries
                      <vue-number-input
                        disabled
                        v-model="editedItem.series"
                        center
                        size="small"
                        controls
                      ></vue-number-input>
                    </v-col>
                    <v-col cols="12" sm="4">
                      Temps d'exécution
                      <vue-number-input
                        disabled
                        v-model="editedItem.executionDuration"
                        center
                        size="small"
                        controls
                      ></vue-number-input>
                    </v-col>
                    <v-col cols="12" sm="4">
                      Temps de récupération
                      <vue-number-input
                        disabled
                        v-model="editedItem.recoveryDuration"
                        center
                        size="small"
                        controls
                      ></vue-number-input>
                    </v-col>
                    <v-col cols="12" sm="4">
                      Nombre de répétitions
                      <vue-number-input
                        disabled
                        v-model="editedItem.repetition"
                        center
                        size="small"
                        controls
                      ></vue-number-input>
                    </v-col>

                    <v-col cols="12" sm="12">
                      Description
                      <VueEditor
                        disabled
                        v-model="editedItem.description"
                        :editorToolbar="customToolbar"
                      ></VueEditor>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red darken-1"
                  text
                  @click="
                    dialogExercise = false;
                    editedItem = defaultItem;
                  "
                >
                  Annuler
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:expanded-item="{ headers }">
        <td :colspan="headers.length">
          <v-list subheader two-line color="transparent">
            <v-subheader class="text-h6">Exercices</v-subheader>
            <v-list-item v-for="exercise in exercises" :key="exercise._id">
              <v-list-item-avatar>
                <v-icon class="grey lighten-1" dark> mdi-weight-lifter </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  {{ exercise.exercice.label }}
                </v-list-item-title>

                <v-list-item-subtitle>
                  Ajouté le: {{ exercise.createdAt }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <div>
                  <v-btn
                    @click="
                      editedItem = exercise.exercice;
                      dialogExercise = true;
                    "
                    rounded
                    small
                    color="primary"
                    class="mr-3"
                  >
                    <v-icon left>mdi-eye</v-icon>
                    Voir
                  </v-btn>
                  <v-btn
                    rounded
                    outlined
                    color="red"
                    small
                    @click="deleteItem(exercise)"
                  >
                    <v-icon left>mdi-close-circle</v-icon>
                    Retirer
                  </v-btn>
                </div>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </td>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <!--
        Attribution manuelle des protocole

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon small @click="showClients(item)">
                mdi-account-arrow-left
              </v-icon>
            </v-btn>
          </template>
<span>Attribuer</span>
</v-tooltip>
-->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon small @click="editItem(item)"> mdi-pencil </v-icon>
            </v-btn>
          </template>
          <span>Modifier</span>
        </v-tooltip>
        <!--
        suppression des protocoles

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
            </v-btn>
          </template>
          <span>Supprimer</span>
        </v-tooltip>
        -->
      </template>

      <template v-slot:[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }} de
        {{ items.itemsLength }}
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Actualiser </v-btn>
      </template>
    </v-data-table>
  </section>
</template>

<script>
import service from "@/services/index";
import Loader from "@/components/Loader.vue";
import FloatingAlert from "@/components/FloatingAlert.vue";
import ClientList from "@/components/Admin/Protocols/ClientList.vue";

export default {
  components: { Loader, FloatingAlert, ClientList },
  data: () => ({
    search: "",
    startDateMenu: false,
    endDateMenu: false,
    expanded: [],
    loadingTable: false,
    dialogUsers: false,
    dialogExercise: false,
    loading: false,
    attributionDialog: false,
    dialog: false,
    dialogDelete: false,
    selectedProtocols: [],
    selectedClientId: "",
    headers: [
      {
        text: "Intitulé",
        align: "start",
        value: "label",
      },
      { text: "Objectif", value: "objectif" },
      { text: "Sexe", value: "gender" },
      { text: "Debut Protocole", value: "startDate" },
      { text: "Fin protocole", value: "endDate" },
      // { text: "Durée", value: "duration" },
      { text: "Actions", value: "actions", sortable: false },
      { text: "", value: "data-table-expand" },
    ],
    objective: [
      { objectif: "Perte de poids" },
      { objectif: "Prise de masse" },
      { objectif: "Remise en forme" },
    ],
    gender: [{ sexe: "M" }, { sexe: "F" }],
    protocols: [],
    exercises: [],
    protocolId: "",
    editedIndex: -1,
    exerciseAndProtocolIds: {
      exerciseId: "",
      protocolId: "",
    },
    defaultExerciseAndProtocolIds: {
      exerciseId: "",
      protocolId: "",
    },
    editedItem: {
      label: "",
      duration: 0,
      objective: "",
      gender: "",
      startDate: "",
      endDate: "",
      description: "",
    },
    defaultItem: {
      label: "",
      duration: 0,
      objectif: "",
      gender: "",
      startDate: "",
      endDate: "",
      description: "",
    },
    clients: [],
    customToolbar: [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ["bold", "italic", "underline"],
      [{ color: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
    alertMessage: "",
    alertShow: null,
    alertType: "",
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nouveau protocole"
        : "Modifier un protocole";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    expanded(protocol) {
      if (protocol.length > 0) {
        this.getProtocolExercises(protocol[0]._id);
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.protocols = [];
      this.loadingTable = true;
      service
        .getProtocols()
        .then((response) => {
          this.protocols = response.data.data.map((protocol) => {
            return {
              ...protocol,
              startDate: this.formatedDate(protocol.startDate),
              endDate: this.formatedDate(protocol.endDate),
            };
          });
          console.log(this.protocols);
          this.loadingTable = false;
        })
        .catch((err) => {
          console.log(err);
          this.loadingTable = false;
        });
    },
    formatedDate(date) {
      const d = new Date(date);
      if (isNaN(d.getTime())) {
        return "";
      }
      const day = String(d.getDate()).padStart(2, "0");
      const month = String(d.getMonth() + 1).padStart(2, "0");
      const year = d.getFullYear();
      return `${day}-${month}-${year}`;
    },
    manageAlert(display, type, message) {
      // this.alertShow = false;
      this.alertShow = display;
      this.alertType = type;
      this.alertMessage = message;
    },
    showClients(item) {
      this.protocolId = item._id;
      this.dialogUsers = true;
    },
    getClients() {
      this.loading = true;
      service
        .getClients()
        .then((results) => {
          this.clients = results.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    multiProtocolAttribution() {
      this.loading = true;
      let protocolIds = this.selectedProtocols.map((protocol) => {
        return protocol._id;
      });
      service
        .multiProtocolAttribution(this.selectedClientId, protocolIds)
        .then((results) => {
          console.log(results);
          this.selectedProtocols = [];
          this.selectedClientId = "";
          this.loading = false;
          this.attributionDialog = false;
          this.manageAlert(true, "success", "Protocole attribué");
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.manageAlert(true, "error", "Echec de l'attribution");
        });
    },

    editItem(item) {
      this.editedIndex = this.protocols.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      if (item.exercice) {
        console.log(item);
        this.exerciseAndProtocolIds.exerciseId = item.exercice._id;
        this.exerciseAndProtocolIds.protocolId = item.protocol;
      } else {
        this.editedIndex = this.protocols.indexOf(item);
        this.editedItem = Object.assign({}, item);
      }
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.loading = true;
      if (this.exerciseAndProtocolIds.exerciseId) {
        service
          .removeExerciseToProtocol(
            this.exerciseAndProtocolIds.protocolId,
            this.exerciseAndProtocolIds.exerciseId
          )
          .then((response) => {
            this.getProtocolExercises(this.exerciseAndProtocolIds.protocolId);
            this.closeDelete();
            this.manageAlert(true, "success", response.message);
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.manageAlert(true, "red", err.message);
          });
      } else {
        service
          .deleteProtocol(this.editedItem._id)
          .then((response) => {
            this.protocols.splice(this.editedIndex, 1);
            this.closeDelete();
            this.manageAlert(true, "success", response.message);
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.manageAlert(true, "red", err.message);
            this.closeDelete();
          });
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.exerciseAndProtocolIds = Object.assign(
          {},
          this.defaultExerciseAndProtocolIds
        );
        this.editedIndex = -1;
      });
    },

    save() {
      const formatDate = (date) => {
        if (!date) return null;
        const d = new Date(date);
        if (isNaN(d.getTime())) {
          this.manageAlert(true, "error", "Date invalide");
          return null;
        }
        const day = String(d.getDate()).padStart(2, "0");
        const month = String(d.getMonth() + 1).padStart(2, "0");
        const year = d.getFullYear();
        return `${month}-${day}-${year}`;
      };
      this.editedItem.startDate = formatDate(this.editedItem.startDate);
      this.editedItem.endDate = formatDate(this.editedItem.endDate);
      if (!this.editedItem.startDate || !this.editedItem.endDate) {
        return;
      }

      console.log(this.editedItem);
      this.editedItem.objective = this.editedItem.objective.objectif;
      this.editedItem.gender = this.editedItem.gender.sexe;
      if (this.editedIndex > -1) {
        this.loading = true;
        service
          .updateProcotol(this.editedItem._id, this.editedItem)
          .then((response) => {
            console.log(response);
            this.initialize();
            this.loading = false;
            this.close();
            this.manageAlert(true, "success", "Modification effectuée");
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            this.close();
            this.manageAlert(
              true,
              "error",
              "Echec de modification du protocole"
            );
          });
      } else {
        this.loading = true;
        service
          .createProcotol(this.editedItem)
          .then((response) => {
            console.log(response);
            this.protocols.push(this.editedItem);
            this.loading = false;
            this.close();
            this.manageAlert(true, "success", "Nouveau protocole créé");
          })
          .catch((err) => {
            console.log(err);
            this.manageAlert(true, "error", "Echec de création du protocole");
          });
      }
    },
    getProtocolExercises(protocolId) {
      this.loading = true;
      service
        .getProtocolExercises(protocolId)
        .then((response) => {
          this.loading = false;
          this.exercises = response.data;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
