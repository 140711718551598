import axios from "axios";
import server from "../config/address";

let headers = {
	authorization: localStorage.getItem("token"),
};
/**
 * Function used for authentication
 */
async function login(userCredentials) {
	try {
		let results = await axios.post(`${server.url}/admin/login`, {
			phone: userCredentials.phone,
			password: userCredentials.password,
		});
		localStorage.setItem("token", results.data.token);
		return results.data.token;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/**
 * Used for registration of admin users (SECRETAIRE & COACH)
 */
async function register(userDetails) {
	try {
		let results = await axios.post(
			`${server.url}/admin/register`,
			userDetails
		);
		return results;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/**
 * Get specific type of users
 */
async function getSpecificUsers(userType) {
	try {
		let results = await axios.get(`${server.url}/admin/type/${userType}`, {
			headers,
		});
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/**
 * Get sponsorships
 */
async function getSponsorships() {
	try {
		let results = await axios.get(`${server.url}/customer/sponsorships`, {
			headers,
		});
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/**
 * Get all customers
 */
async function getClients() {
	try {
		let results = await axios.get(`${server.url}/customer/all`, {
			headers,
		});
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

async function getClient(clientId) {
	try {
		let results = await axios.get(
			`${server.url}/customer/dashboard/${clientId}`,
			{
				headers,
			}
		);
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/**
 * Retrieve all subscriptions
 */
async function getSubscriptions() {
	try {
		let results = await axios.get(`${server.url}/subscription/`, {
			headers,
		});
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/**
 * Get one specified subscription
 */
async function getSingleSubscription(subscriptionId) {
	try {
		let results = await axios.get(
			`${server.url}/subscription/${subscriptionId}`,
			{ headers }
		);
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/**
 * Retrieve customer subscription
 */
async function getCustomerSubscription(customerId) {
	try {
		let results = await axios.get(
			`${server.url}/subscription/customer/${customerId}`,
			{ headers }
		);
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/** Modifiy subscription details */
async function updateSubscription(subscriptionId, subscriptionDetails) {
	try {
		let results = await axios.put(
			`${server.url}/subscription/${subscriptionId}`,
			{ subscriptionDetails },
			{ headers }
		);
		return results;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/** Delete a single subscription */
async function deleteSubscription(subscriptionId) {
	try {
		let results = await axios.delete(
			`${server.url}/subscription/${subscriptionId}`,
			{ headers }
		);
		return results;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/** Create a new subscription */
async function createSubscription(subscriptionDetails) {
	try {
		let results = await axios.post(
			`${server.url}/subscription`,
			subscriptionDetails,
			{ headers }
		);
		return results;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/** Attribute a subscription to a customer */
async function attributionSubscription(
	subscriptionId,
	customerId,
	subscriptionDate
) {
	try {
		let results = await axios.put(
			`${server.url}/subscription/${subscriptionId}/customer/${customerId}`,
			{ subscriptionDate },
			{ headers }
		);
		return results;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

async function updateInscription(customerId, registered) {
	try {
		let results = await axios.put(
			`${server.url}/customer/${customerId}/inscription`,
			{ registered },
			{ headers }
		);
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/**
 * FOOD REQUESTS
 */
/** Create a new food */
async function createFood(foodDetails) {
	try {
		let results = await axios.post(
			`${server.url}/food`,
			{ foodDetails },
			{ headers }
		);
		return results;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/** Get all foods */
async function getFoods() {
	try {
		let results = await axios.get(`${server.url}/food`, { headers });
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/** Get all foods */
async function getFoodsAttributions() {
	try {
		let results = await axios.get(`${server.url}/food/attributions`, {
			headers,
		});
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/** Get customer foods */
async function getCustomerFood(customerId) {
	try {
		let results = await axios.get(
			`${server.url}/food/admin/customer/${customerId}`,
			{ headers }
		);
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

async function foodImageUpload(image) {
	try {
		let results = await axios.put(`${server.url}/food/image`, image);
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/** Delete single food */
async function deleteFood(foodId) {
	try {
		let results = await axios.delete(`${server.url}/food/${foodId}`, {
			headers,
		});
		return results;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/** Update single food */
async function updateFood(foodId, foodDetails) {
	try {
		let results = await axios.put(
			`${server.url}/food/${foodId}`,
			{
				foodDetails,
			},
			{ headers }
		);
		return results;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/** Attribute food to a customer */
async function attributeFood(customers, foodId, period) {
	try {
		let results = await axios.put(
			`${server.url}/food/${foodId}/customers/`,
			{ period, customers },
			{ headers }
		);
		return results;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/** Desattribute food to a customer */
async function removeAttribution(attributionId) {
	try {
		let results = await axios.delete(
			`${server.url}/food/attribution/${attributionId}`,
			{ headers }
		);
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/**
 * FOOD CATEGORIES
 */
async function getFoodCategories() {
	try {
		let results = await axios.get(`${server.url}/food/categories`, {
			headers,
		});
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

async function createFoodCategory(foodCategory) {
	try {
		let results = await axios.post(
			`${server.url}/food/category`,
			foodCategory,
			{ headers }
		);
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}
async function updateFoodCategory(foodCategoryId, foodCategory) {
	try {
		let results = await axios.put(
			`${server.url}/food/category/${foodCategoryId}`,
			foodCategory,
			{ headers }
		);
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}
async function deleteFoodCategory(foodCategoryId) {
	try {
		let results = await axios.delete(
			`${server.url}/food/category/${foodCategoryId}`,
			{ headers }
		);
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/** PROTOCOLS REQUESTS */
/** Create a new protocol */
async function createProcotol(protocolDetails) {
	try {
		let results = await axios.post(
			`${server.url}/protocol`,
			{ protocolDetails },
			{ headers }
		);
		return results;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

async function updateProcotol(protocolId, protocolDetails) {
	try {
		let results = await axios.put(
			`${server.url}/protocol/update/${protocolId}/`,
			{ protocolDetails },
			{ headers }
		);
		return results;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

async function getProtocols() {
	try {
		let results = await axios.get(`${server.url}/protocol`, { headers });
		return results;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

async function getProtocol(customerId) {
	try {
		let results = await axios.get(
			`${server.url}/protocol/customer/${customerId}`,
			{ headers }
		);
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

async function deleteProtocol(protocolId) {
	try {
		let results = await axios.delete(
			`${server.url}/protocol/${protocolId}`,
			{ headers }
		);
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

async function endProtocol(attributionId) {
	try {
		let results = await axios.delete(
			`${server.url}/protocol/attribution/${attributionId}`,
			{ headers }
		);
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

async function singleProtocolAttribution(protocolId, customerProtocols) {
	try {
		let results = await axios.post(
			`${server.url}/protocol/${protocolId}/customer`,
			{ customerProtocols },
			{ headers }
		);
		return results;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

async function multiProtocolAttribution(customerId, protocolIds) {
	try {
		let results = await axios.post(
			`${server.url}/protocol/multi-attribution/customer/${customerId}`,
			{ protocolIds },
			{ headers }
		);
		return results;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

async function getCustomerProtocols(customerId) {
	try {
		let results = await axios.get(
			`${server.url}/protocol/customer/${customerId}`,
			{ headers }
		);
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/** EXERCISE MANAGEMENT
 *
 * Get all exercises
 */
async function getExercises() {
	try {
		let results = await axios.get(`${server.url}/exercice`, {
			headers,
		});
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/** EXERCISE MANAGEMENT
 *
 * Add an exercise one at a time
 */
async function addExercise(exercise) {
	try {
		let results = await axios.post(`${server.url}/exercice`, exercise, {
			headers,
		});
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/** EXERCISE MANAGEMENT
 *
 * Update an exercise
 */
async function updateExercise(exerciseId, exercise) {
	try {
		let results = await axios.put(
			`${server.url}/exercice/${exerciseId}`,
			exercise,
			{
				headers,
			}
		);
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/** EXERCISE MANAGEMENT
 *
 * Delete an exercise
 */
async function deleteExercise(exerciseId) {
	try {
		let results = await axios.delete(
			`${server.url}/exercice/${exerciseId}`,
			{
				headers,
			}
		);
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/** EXERCISE MANAGEMENT
 *
 * Synchronize an exercise with a protocol
 */
async function syncExerciseWithProtocol(exerciseIds, protocolId) {
	try {
		let results = await axios.post(
			`${server.url}/exercice/protocol/${protocolId}`,
			{ exercices: exerciseIds },
			{
				headers,
			}
		);
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/** EXERCISE MANAGEMENT
 *
 * Desynchronize an exercise with a protocol
 */
async function removeExerciseToProtocol(protocolId, exerciseId) {
	try {
		let results = await axios.delete(
			`${server.url}/exercice/${exerciseId}/protocol/${protocolId}`,
			{
				headers,
			}
		);
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/** EXERCISE MANAGEMENT
 *
 * Get exercise's protocol
 */
async function getProtocolExercises(protocolId) {
	try {
		let results = await axios.get(
			`${server.url}/exercice/protocol/${protocolId}`,
			{
				headers,
			}
		);
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/** SESSION MANAGEMENT
 *
 * Get sessions
 */
async function getSessions() {
	try {
		let results = await axios.get(`${server.url}/session`, { headers });
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/** SESSION MANAGEMENT
 *
 * add new session
 */
async function addSession(sessionDetails) {
	try {
		let results = await axios.post(
			`${server.url}/session`,
			sessionDetails,
			{ headers }
		);
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/** SESSION MANAGEMENT
 *
 * update session
 */
async function updateSession(sessionId, updatedSessionDetails) {
	try {
		let results = await axios.put(
			`${server.url}/session/${sessionId}`,
			updatedSessionDetails,
			{ headers }
		);
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/** SESSION MANAGEMENT
 *
 * delete session
 */
async function deleteSession(sessionId) {
	try {
		let results = await axios.delete(`${server.url}/session/${sessionId}`, {
			headers,
		});
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/** SESSION MANAGEMENT | PROGRAMMED SESSION MANAGEMENT
 *
 * Get programmed sessions
 */
async function getProgrammedSessions() {
	try {
		let results = await axios.get(`${server.url}/session/attributions`, {
			headers,
		});
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/** SESSION MANAGEMENT | PROGRAMMED SESSION MANAGEMENT
 *
 * add session programmation
 */
async function attributeSession(attributionDetails) {
	try {
		let results = await axios.post(
			`${server.url}/session/attribution`,
			attributionDetails,
			{ headers }
		);
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/** SESSION MANAGEMENT | PROGRAMMED SESSION MANAGEMENT
 *
 * delete session attribution
 */
async function deleteProgrammedSession(programmedSessionId) {
	try {
		let results = await axios.delete(
			`${server.url}/session/attribution/${programmedSessionId}`,
			{ headers }
		);
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

// Manage presences
async function getPresences() {
	try {
		let results = await axios.get(`${server.url}/presence`, { headers });
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

async function otp(code) {
	try {
		let results = await axios.post(
			`${server.url}/otp/admin/validate`,
			{ code: code.otp },
			{ headers }
		);
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

async function changeClientStatus(customerId, status) {
	try {
		let results = await axios.put(
			`${server.url}/customer/status/${customerId}`,
			{ validation: status }
		);
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

async function getRewards() {
	try {
		let results = await axios.get(`${server.url}/reward`, { headers });
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

async function attributeReward(customerId, point) {
	try {
		let results = await axios.put(
			`${server.url}/reward/customer/${customerId}`,
			{ point },
			{ headers }
		);
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

async function addReward(reward) {
	try {
		let results = await axios.post(
			`${server.url}/reward`,
			{ rewardDetails: reward },
			{ headers }
		);
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/** ASSISTANCE MANAGEMENT
 *
 * Get customers assistance demands
 */
async function getCustomersProblems() {
	try {
		let results = await axios.get(`${server.url}/assisstance-client`, {
			headers,
		});
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/** ASSISTANCE MANAGEMENT
 *
 * Change customer demand status
 */
async function changeProblemStatus(problemId, status) {
	try {
		let results = await axios.put(
			`${server.url}/assisstance-client/${problemId}/status`,
			{ status },
			{
				headers,
			}
		);
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/** FAQ MANAGEMENT
 *
 * Get FAQs
 */
async function getQuestions() {
	try {
		let results = await axios.get(`${server.url}/faq`, {
			headers,
		});
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/** FAQ MANAGEMENT
 *
 * Add question/answer
 */
async function addQuestionAnswer(questionAnswer) {
	try {
		let results = await axios.post(`${server.url}/faq`, questionAnswer, {
			headers,
		});
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/** FAQ MANAGEMENT
 *
 * Update question/answer
 */
async function updateQuestionAnswer(questionAnswer, questionAnswerId) {
	try {
		let results = await axios.put(
			`${server.url}/faq/${questionAnswerId}`,
			questionAnswer,
			{
				headers,
			}
		);
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/** FAQ MANAGEMENT
 *
 * Delete question/answer
 */
async function deleteQuestionAnswer(questionAnswerId) {
	try {
		let results = await axios.delete(
			`${server.url}/faq/${questionAnswerId}`,
			{
				headers,
			}
		);
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/** TRANSACTIONS MANAGEMENT
 *
 * Create new payment
 */
async function createPayment(paymentDetails) {
	try {
		let results = await axios.post(
			`${server.url}/transaction/pay`,
			paymentDetails,
			{
				headers,
			}
		);
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

/** TRANSACTIONS MANAGEMENT
 *
 * Get all transactions
 */
async function getTransactions() {
	try {
		let results = await axios.get(`${server.url}/transaction/`, {
			headers,
		});
		return results.data;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
}

export default {
	changeClientStatus,
	otp,
	login,
	register,
	getSpecificUsers,
	updateInscription,
	attributionSubscription,
	createSubscription,
	deleteSubscription,
	getCustomerSubscription,
	getSingleSubscription,
	updateSubscription,
	getSubscriptions,
	createFood,
	getFoods,
	getCustomerFood,
	getFoodsAttributions,
	deleteFood,
	updateFood,
	attributeFood,
	removeAttribution,
	getFoodCategories,
	createFoodCategory,
	updateFoodCategory,
	deleteFoodCategory,
	getRewards,
	attributeReward,
	addReward,
	createProcotol,
	getCustomerProtocols,
	updateProcotol,
	singleProtocolAttribution,
	multiProtocolAttribution,
	getProtocols,
	getProtocol,
	deleteProtocol,
	endProtocol,
	getExercises,
	addExercise,
	updateExercise,
	deleteExercise,
	syncExerciseWithProtocol,
	getProtocolExercises,
	removeExerciseToProtocol,
	getClients,
	getClient,
	getSponsorships,
	foodImageUpload,
	getPresences,
	getSessions,
	addSession,
	updateSession,
	deleteSession,
	getProgrammedSessions,
	attributeSession,
	deleteProgrammedSession,
	getCustomersProblems,
	changeProblemStatus,
	getQuestions,
	addQuestionAnswer,
	updateQuestionAnswer,
	deleteQuestionAnswer,
	createPayment,
	getTransactions
};
