<template>
	<section>
		<loader :dialog="loading"></loader>
		<floating-alert
			:message="alertMessage"
			:show="alertShow"
			:type="alertType"
		></floating-alert>
		<v-card>
			<v-card-title
				>Attribution du repas
				<!-- <v-spacer></v-spacer>
				<v-btn @click="dialogUsers = false" icon
				><v-icon>mdi-close-circle</v-icon></v-btn
				> -->
			</v-card-title>
			<v-card-text class="pt-3">
				<v-form ref="form" v-model="valid" lazy-validation>
					<v-text-field
						v-model="food.label"
						label="Nom du repas"
						readonly
						dense
					></v-text-field>
					<v-row class="my-5">
						<v-col cols="12" sm="8">
							<v-menu
								ref="dateModal"
								v-model="dateModal"
								:close-on-content-click="false"
								:return-value.sync="period.date"
								transition="scale-transition"
								offset-y
								min-width="auto"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-combobox
										v-model="period.date"
										multiple
										chips
										dense
										small-chips
										label="Dates de prise"
										prepend-icon="mdi-calendar"
										readonly
										v-bind="attrs"
										v-on="on"
									></v-combobox>
								</template>
								<v-date-picker
									v-model="period.date"
									multiple
									no-title
									scrollable
									color="#E31B1E"
								>
									<v-spacer></v-spacer>
									<v-btn
										text
										color="#E31B1E"
										@click="dateModal = false"
									>
										Annuler
									</v-btn>
									<v-btn
										text
										color="primary"
										@click="
											$refs.dateModal.save(period.date)
										"
									>
										OK
									</v-btn>
								</v-date-picker>
							</v-menu>
							<!-- <v-menu
								v-model="dateModal"
								:close-on-content-click="false"
								transition="scale-transition"
								offset-y
								:return-value.sync="period.date"
								min-width="auto"
								ref="dateModal"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-combobox
										v-model="period.date"
										multiple
										chips
										small-chips
										label="Date de prise"
										prepend-icon="mdi-calendar"
										readonly
										dense
										:rules="rules"
										v-bind="attrs"
										v-on="on"
									></v-combobox>
								</template>

								<v-date-picker
									multiple
									no-title
									v-model="period.date"
									locale="fr"
									scrollable
								></v-date-picker>
							</v-menu> -->
						</v-col>
						<v-col cols="12" sm="4">
							<v-menu
								ref="menu"
								v-model="timeModal"
								:close-on-content-click="false"
								:nudge-right="40"
								:return-value.sync="period.time"
								transition="scale-transition"
								offset-y
								max-width="290px"
								min-width="290px"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-model="period.time"
										label="Heure"
										prepend-icon="mdi-clock-time-four-outline"
										readonly
										dense
										:rules="rules"
										v-bind="attrs"
										v-on="on"
									></v-text-field>
								</template>
								<v-time-picker
									color="#E31B1E"
									v-model="period.time"
									format="24hr"
									full-width
									@click:minute="$refs.menu.save(period.time)"
								>
								</v-time-picker>
							</v-menu>
						</v-col>
					</v-row>
					<v-autocomplete
						v-model="customerFoods"
						:items="clients"
						:rules="rules"
						item-text="fullName"
						item-value="_id"
						chips
						dense
						label="Clients"
						multiple
					>
						<template #selection="{ item }">
							<v-chip color="primary">{{ item.fullName }}</v-chip>
						</template>
					</v-autocomplete>
				</v-form>
			</v-card-text>
			<v-card-actions
				><v-btn text color="error" @click="$emit('close-client-dialog')"
					>Fermer</v-btn
				>
				<v-spacer></v-spacer>
				<v-btn :disabled="!valid" text color="primary" @click="validate"
					>Confirmer</v-btn
				>
			</v-card-actions>
		</v-card>
	</section>
</template>

<script>
import Loader from "@/components/Loader.vue";
import FloatingAlert from "@/components/FloatingAlert.vue";
import service from "@/services";
export default {
	props: ["food"],
	data() {
		return {
			valid: true,
			period: {
				time: "",
				date: [],
			},
			timeModal: false,
			dateModal: false,
			loadingTable: false,
			loading: false,
			clients: [],
			customerFoods: null,
			rules: [(v) => !!v || "Veuillez remplir ce champ"],
			alertMessage: "",
			alertShow: null,
			alertType: "",
		};
	},
	components: { Loader, FloatingAlert },
	mounted() {
		this.showClients();
	},
	methods: {
		manageAlert(display, type, message) {
			this.alertShow = display;
			this.alertType = type;
			this.alertMessage = message;
		},
		showClients() {
			this.loading = true;
			service
				.getClients()
				.then((response) => {
					this.clients = response.data;
					this.loading = false;
				})
				.catch((err) => {
					console.log(err);
				});
		},
		validate() {
			this.alertShow = false;
			if (this.$refs.form.validate()) {
				this.loading = true;
				service
					.attributeFood(
						this.customerFoods,
						this.food._id,
						this.period
					)
					.then((response) => {
						this.loading = false;
						this.manageAlert(true, "success", "Repas attribué");
						this.period = {
							time: "",
							date: [],
						};
						// this.$emit("close-client-dialog");
					})
					.catch((err) => {
						console.log(err);
						this.loading = false;
						this.manageAlert(
							true,
							"error",
							"Erreur d'attribution du repas"
						);
					});
			}
		},
	},
};
</script>

<style></style>
