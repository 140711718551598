<template>
	<section>
		<loader :dialog="loading"></loader>
		<floating-alert
			:message="showAlertMessage"
			:show="showAlert"
			:type="showAlertColor"></floating-alert>
		<v-container>
			<v-row>
				<v-col cols="12" sm="12" md="4">
					<v-text-field
						label="Rechercher un client"
						append-icon="mdi-magnify"
						v-model="search"></v-text-field>
				</v-col>
				<!-- DIALOG DELETE -->
				<v-dialog v-model="dialogConfirm" max-width="500px">
					<v-card>
						<v-card-title class="text-h5"
							>Veuillez confirmer cette opération</v-card-title
						>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="red darken-1"
								text
								@click="dialogConfirm = false"
								>Annuler</v-btn
							>
							<v-btn
								color="blue darken-1"
								text
								@click="changeStatus(problemId, 'Résolu')"
								>Confirmer</v-btn
							>
							<v-spacer></v-spacer>
						</v-card-actions>
					</v-card>
				</v-dialog>

				<!-- DIALOG about problem details -->
				<v-dialog v-model="dialogDetails" max-width="400px">
					<v-card class="mx-auto">
						<v-card-title>{{
							problemDetails.customer.fullName
						}}</v-card-title>
						<v-card-text>
							<div class="text--primary">
								{{ problemDetails.subject }}
							</div>
						</v-card-text>
						<v-card-actions>
							<span class="grey--text ml-2">
								{{
									moment(problemDetails.createdAt)
										.locale("fr")
										.format("DD MMM YYYY")
								}}</span
							>
							<v-spacer></v-spacer>
							<v-btn
								rounded
								small
								text
								color="error"
								@click="dialogDetails = false">
								Fermer
							</v-btn>
							<v-btn
								v-if="problemDetails.status == 'En attente'"
								rounded
								small
								color="primary"
								@click="
									problemId = problemDetails._id;
									dialogConfirm = true;
								">
								<v-icon left>mdi-check</v-icon>
								Valider
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-row>
		</v-container>

		<!-- Customer problems cards -->
		<card-loading v-if="skeletonLoader"></card-loading>
		<div v-else-if="assistanceDemands.length === 0">
			<not-found></not-found>
			<v-row class="justify-center">
				<v-btn
					color="red"
					dark
					rounded
					class="mt-5"
					@click="initialize">
					<v-icon>mdi-refresh</v-icon>
					Actualiser</v-btn
				>
			</v-row>
		</div>
		<v-row class="mt-5" v-else>
			<v-col
				sm="12"
				md="4"
				v-for="problem in filteredProblems"
				:key="problem._id">
				<v-card class="mx-auto rounded-xl" max-width="344" outlined>
					<v-list-item three-line>
						<v-list-item-avatar size="60">
							<v-icon size="50" color="red"
								>mdi-hand-heart-outline</v-icon
							>
						</v-list-item-avatar>
						<v-list-item-content>
							<v-list-item-title class="text-h6 mb-1">
								{{ problem.customer.fullName }}
								<v-chip
									small
									color="success"
									class="float-right"
									v-if="problem.status == 'Résolu'"
									><v-icon left small>mdi-check</v-icon>
									Résolu</v-chip
								>
							</v-list-item-title>
							<v-list-item-subtitle>{{
								problem.subject
							}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>

					<v-card-actions>
						<span class="grey--text ml-2">
							{{
								moment(problem.createdAt)
									.locale("fr")
									.format("DD MMM YYYY")
							}}</span
						>
						<v-spacer></v-spacer>
						<v-btn
							rounded
							x-small
							outlined
							color="error"
							@click="showProblemDetails(problem)">
							<v-icon left>mdi-eye</v-icon>
							Voir
						</v-btn>
						<v-btn
							v-if="problem.status == 'En attente'"
							rounded
							x-small
							color="primary"
							@click="
								problemId = problem._id;
								dialogConfirm = true;
							">
							<v-icon left>mdi-check</v-icon>
							Valider
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</section>
</template>

<script>
	import moment from "moment";
	import service from "../services";
	import FloatingAlert from "@/components/FloatingAlert.vue";
	import NotFound from "@/components/Notfound.vue";
	import CardLoading from "@/components/CardLoading.vue";
	import Loader from "@/components/Loader.vue";
	export default {
		components: {
			Loader,
			FloatingAlert,
			NotFound,
			CardLoading,
		},
		data: () => ({
			moment,
			skeletonLoader: false,
			loading: false,
			dialogConfirm: false,
			dialogDetails: false,
			showAlert: false,
			showAlertMessage: "",
			showAlertColor: "",
			problemId: "",
			problemDetails: {
				customer: {},
			},
			assistanceDemands: [],
			search: "",
			headers: [
				{
					text: "Demande",
					value: "subject",
				},
				{ text: "Description", value: "description" },
				{ text: "Client", value: "customer.fullName" },
				{ text: "Statut", value: "status" },
				{ text: "Envoyé le", value: "createdAt", align: "center" },
				{ text: "Actions", value: "actions", sortable: false },
			],
		}),
		computed: {
			filteredProblems() {
				return this.assistanceDemands.filter((problem) => {
					return problem.customer.fullName
						.toLowerCase()
						.includes(this.search.toLowerCase());
				});
			},
		},
		created() {
			this.initialize();
		},
		methods: {
			initialize() {
				this.assistanceDemands = [];
				this.skeletonLoader = true;
				service
					.getCustomersProblems()
					.then((response) => {
						this.assistanceDemands = response.data;
						this.skeletonLoader = false;
					})
					.catch((err) => {
						console.log(err);
						this.skeletonLoader = false;
					});
			},
			manageAlert(display, type, message) {
				this.showAlert = display;
				this.showAlertColor = type;
				this.showAlertMessage = message;
			},
			changeStatus(problemId, status) {
				this.loading = true;
				service
					.changeProblemStatus(problemId, status)
					.then((response) => {
						this.manageAlert(true, "success", response.message);
						this.loading = false;
						this.closeConfirm();
					});
			},
			showProblemDetails(problem) {
				this.dialogDetails = true;
				this.problemDetails = problem;
			},
			closeConfirm() {
				this.dialogConfirm = false;
				this.dialogDetails = false;
				this.initialize();
			},
		},
	};
</script>

<style></style>
