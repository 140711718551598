<template>
	<v-card>
		<loader :dialog="loading"></loader>
		<floating-alert
			:message="alertMessage"
			:show="alertShow"
			:type="alertType"></floating-alert>
		<v-card-title>
			Liste des protocoles
			<v-spacer></v-spacer>
			<v-btn icon @click="$emit('close-protocol-dialog')"
				><v-icon>mdi-close-circle</v-icon></v-btn
			>
		</v-card-title>

		<v-card-text>
			<v-alert type="warning" v-if="protocols.length == 0">
				Aucun protocole pour le moment</v-alert
			>
			<v-expansion-panels v-else v-model="panel">
				<v-expansion-panel
					v-for="(item, index) in protocols"
					:key="index">
					<v-expansion-panel-header>
						<v-row>
							<v-col cols="8">
								{{ item.protocol.label }}
							</v-col>
							<v-col cols="4">
								<v-chip
									:class="
										item.status === 'Inactif'
											? 'error'
											: 'success'
									"
									small>
									{{ item.status }}
									<!-- <v-avatar right>
										<v-icon dark small>mdi-refresh</v-icon>
									</v-avatar> -->
								</v-chip>
							</v-col>
						</v-row>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<span v-html="item.protocol.description"></span>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
			<v-dialog v-model="dialogDelete" max-width="500px">
				<v-card>
					<v-card-title class="text-h5"
						>Êtes-vous sûrs de vouloir effectuer cette
						action?</v-card-title
					>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="red darken-1"
							text
							@click="dialogDelete = false"
							>Annuler</v-btn
						>
						<v-btn color="blue darken-1" text @click="stopProtocol"
							>OK</v-btn
						>
						<v-spacer></v-spacer>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-card-text>
		<v-card-actions v-if="panel >= 0"
			><v-btn block color="error" @click="dialogDelete = true"
				>Fermer le protocole</v-btn
			></v-card-actions
		>
	</v-card>
</template>

<script>
	import service from "@/services";
	import Loader from "@/components/Loader.vue";
	import FloatingAlert from "@/components/FloatingAlert.vue";
	export default {
		components: { Loader, FloatingAlert },
		props: ["protocols"],
		data() {
			return {
				loading: false,
				panel: undefined,
				dialogDelete: false,
				dialogChangeStatus: false,
				alertMessage: "",
				alertShow: false,
				alertType: "",
			};
		},
		methods: {
			stopProtocol() {
				this.loading = true;
				let attributionId = this.protocols[this.panel]._id;
				service
					.endProtocol(attributionId)
					.then((results) => {
						this.protocols.splice(this.panel, 1);
						this.panel = undefined;
						this.alertMessage = results.message;
						this.alertShow = true;
						this.alertType = "success";
					})
					.catch((err) => {
						this.alertMessage = err.message;
						this.alertShow = true;
						this.alertType = "error";
					})
					.finally(() => {
						this.loading = false;
						this.dialogDelete = false;
					});
			},
		},
	};
</script>

<style></style>
