<template>
	<section>
		<v-card>
			<v-card-title>
				<v-avatar size="50" color="#076BC0" class="mr-3">
					<img
						v-if="customer.photo"
						alt="user"
						:src="customer.photo"
					/>
					<span v-else class="white--text text-h5"
						>{{ customer.fullName.charAt(0)
						}}{{ customer.fullName.charAt(1) }}</span
					>
				</v-avatar>
				{{ customer.fullName }}
				<v-spacer></v-spacer>
				<v-btn icon @click="$emit('close-customer-details-dialog')"
					><v-icon>mdi-close-circle</v-icon></v-btn
				>
			</v-card-title>
			<v-card-text>
				<v-list two-line>
					<v-list-item>
						<v-list-item-icon>
							<v-icon color="#0071BA"> mdi-cake </v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title v-if="customer.birthday">{{
								customer.birthday
							}}</v-list-item-title>
							<v-list-item-title v-else
								>Non précisé</v-list-item-title
							>
						</v-list-item-content>
					</v-list-item>

					<v-divider inset></v-divider>
					<v-list-item>
						<v-list-item-icon>
							<v-icon color="#0071BA"> mdi-phone </v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title>{{
								customer.phone
							}}</v-list-item-title>
							<v-list-item-subtitle>Mobile</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>

					<v-divider inset></v-divider>

					<v-list-item>
						<v-list-item-icon>
							<v-icon color="#0071BA"> mdi-email </v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title>{{
								customer.email
							}}</v-list-item-title>
							<v-list-item-subtitle
								>Personnel</v-list-item-subtitle
							>
						</v-list-item-content>
					</v-list-item>

					<v-divider inset></v-divider>

					<v-list-item>
						<v-list-item-icon>
							<v-icon color="#0071BA"> mdi-map-marker </v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title v-if="customer.address">{{
								customer.address
							}}</v-list-item-title>
							<v-list-item-title v-else
								>Non précisé</v-list-item-title
							>
						</v-list-item-content>
					</v-list-item>
					<v-divider inset></v-divider>

					<v-list-item>
						<v-list-item-icon>
							<v-icon color="#0071BA"> mdi-briefcase </v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title v-if="customer.profession">{{
								customer.profession
							}}</v-list-item-title>
							<v-list-item-title v-else
								>Non précisé</v-list-item-title
							>
						</v-list-item-content>
					</v-list-item>
					<v-divider inset></v-divider>

					<v-list-item>
						<v-list-item-icon>
							<v-icon color="#0071BA"> mdi-weight-lifter </v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title
								>Poids: {{ customer.weight }} Kg <br />
								Taille: {{ customer.height }} m
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-card-text>
		</v-card>
	</section>
</template>

<script>
export default {
	props: ["customer"],
};
</script>

<style>
</style>