<template>
	<section>
		<router-view></router-view>
	</section>
</template>

<script>
export default {
	data() {
		return {};
	},

	methods: {},
};
</script>

<style></style>
