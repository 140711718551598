<template>
	<section>
		<v-card id="login_card">
			<v-row class="pa-3">
				<v-col cols="12" sm="12" md="6">
					<v-row justify="center" align="center" class="text-center">
						<v-col cols="6">
							<h1>BIENVENUE AU</h1>
							<img
								src="../assets/cez-logo.png"
								alt="Cez fitness logo"
								style="
									display: block;
									margin-left: auto;
									margin-right: auto;
									width: 50%;
								"
							/>
						</v-col>
					</v-row>

					<SignIn @changeForm="changeForm()" :formShow="signInShow" />
					<SignUp @changeForm="changeForm()" :formShow="signUpShow" />
				</v-col>
				<v-col class="d-none d-sm-none d-md-flex" id="image" cols="6" sm="12" md="6">
					<div id="image_description">
						<v-row type="flex" justify="center">
							<v-col cols="12" sm="10">
								<img
									src="../assets/cez-logo-light.png"
									alt="Cez fitness logo"
									style="
										display: block;
										margin-left: auto;
										margin-right: auto;
										width: 50%;
									"
								/>
								<p
									style="
										font-family: Montserrat;
										color: white;
										text-align: center;
									"
								>
									Entrez vos informations personnelles et
									commencez l’aventure avec nous
								</p>
							</v-col>
						</v-row>
					</div>
				</v-col>
			</v-row>
		</v-card>
	</section>
</template>

<script>
	import SignIn from "../components/Account/SignIn.vue";
	import SignUp from "../components/Account/SignUp.vue";
	export default {
		data() {
			return {
				signUpShow: false,
				signInShow: true,
			};
		},
		components: {
			SignIn,
			SignUp,
		},
		methods: {
			changeForm() {
				this.signInShow = !this.signInShow;
				this.signUpShow = !this.signUpShow;
			},
		},
	};
</script>

<style scoped>
	section {
		background-image: url("../assets/background.svg");
		min-height: 100vh;
		background-size: cover;
	}

	#login_card {
		width: 70%;
		position: absolute;
		top: 10%;
		left: 15%;
		height: 550px;
	}

	#image {
		background-image: url("../assets/muscle2.jpg");
		height: 550px;
		background-size: cover;
	}

	#image_description {
		padding-top: 30%;
	}

	h1 {
		font-family: Megatron;
	}
</style>
