<template>
	<section>
		<loader :dialog="loading"></loader>
		<v-text-field
			flat
			label="Rechercher"
			prepend-inner-icon="mdi-magnify"
			clearable
			v-model="search"
		></v-text-field>
		<v-list>
			<v-list-item v-for="(client, index) in filteredItems" :key="index">
				<v-list-item-avatar>
					<v-icon>mdi-account</v-icon>
				</v-list-item-avatar>

				<v-list-item-content>
					<v-list-item-title
						class="mb-2"
						v-html="client.fullName"
					></v-list-item-title>
					Poids:
					{{ client.weight }} Kg
					<br />
					Taille:
					{{ client.height }} m
				</v-list-item-content>
				<v-list-item-icon>
					<v-menu
						:nudge-width="200"
						offset-x
						:close-on-content-click="false"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon v-bind="attrs" v-on="on">
								<v-icon> mdi-calendar </v-icon>
							</v-btn>
						</template>
						<v-card>
							<v-card-text>
								<v-menu
									v-model="menu"
									:close-on-content-click="false"
									:nudge-right="40"
									transition="scale-transition"
									offset-y
									min-width="auto"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											v-model="date"
											label="Date d'abonnement"
											prepend-icon="mdi-calendar"
											readonly
											v-bind="attrs"
											v-on="on"
										></v-text-field>
									</template>
									<v-date-picker
										locale="Fr"
										color="red"
										v-model="date"
										@input="menu = false"
									></v-date-picker>
								</v-menu>
								<v-btn
									block
									color="primary"
									@click="attributeSubscription(client)"
									><v-icon left>mdi-check-circle</v-icon>
									Confirmer</v-btn
								>
							</v-card-text>
						</v-card>
					</v-menu>
				</v-list-item-icon>
			</v-list-item>
		</v-list>
	</section>
</template>

<script>
import Loader from "@/components/Loader.vue";
import service from "@/services";
export default {
	props: ["clients", "subscriptionId"],
	data() {
		return {
			loading: false,
			search: "",
			menu: false,
			date: "",
		};
	},
	components: { Loader },
	computed: {
		filteredItems() {
			return this.clients.filter((item) => {
				return (
					item.fullName
						.toLowerCase()
						.match(this.search.toLowerCase()) ||
					item.weight.toString().match(this.search.toLowerCase()) ||
					item.height.toString().match(this.search.toLowerCase())
				);
			});
		},
	},
	methods: {
		attributeSubscription(customer) {
			this.loading = true;
			service
				.attributionSubscription(
					this.subscriptionId,
					customer._id,
					this.date
				)
				.then((response) => {
					console.log(response);
					this.loading = false;
					this.menu = false;
					this.$emit("close-clients-list");
				})
				.catch((err) => {
					console.log(err);
					this.loading = false;
				});
		},
	},
};
</script>

<style>
</style>