<template>
  <section>
    <loader :dialog="loading"></loader>
    <floating-alert
      :message="alertMessage"
      :show="alertShow"
      :type="alertType"
    ></floating-alert>
    <v-data-table
      :loading="loadingTable"
      loading-text="Chargement en cours..."
      :headers="headers"
      :items="foods"
      :search="search"
      class="elevation-0 pa-2 rounded-xl"
    >
      <template v-slot:[`item.objectif`]="{ item }">
        {{ item.objective }}
      </template>
      <template v-slot:[`item.weightInterval`]="{ item }">
        [{{ item.weightInterval[0] }} - {{ item.weightInterval[1] }}]
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Liste des repas</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Rechercher"
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px" scrollable>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="red"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-plus</v-icon> Nouveau
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="editedItem.label"
                        label="Intitulé"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        :items="foodCategories"
                        item-text="label"
                        item-value="_id"
                        v-model="editedItem.category"
                        label="Catégorie"
                      ></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        v-model="editedItem.objective"
                        :items="objective"
                        item-value="objectif"
                        item-text="objectif"
                        label="Objectif"
                      ></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        v-model="editedItem.gender"
                        :items="gender"
                        item-value="sexe"
                        item-text="sexe"
                        label="Sexe"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <p>Intervalle de poids</p>
                      <vue-number-input
                        v-model="editedItem.weightInterval[0]"
                        center
                        size="small"
                        :min="0"
                        controls
                      ></vue-number-input>
                      Debut
                    </v-col>
                    <v-col cols="12" sm="6" class="mt-5">
                      <p></p>
                      <vue-number-input
                        v-model="editedItem.weightInterval[1]"
                        center
                        size="small"
                        :min="0"
                        controls
                      ></vue-number-input>
                      Fin
                    </v-col>
                    <v-col cols="12">
                      <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="35"
                        transition="scale-transition"
                        offset-y
                        width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedItem.period"
                            label="Heure de prise"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            dense
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          color="#E31B1E"
                          v-model="editedItem.period"
                          format="24hr"
                          full-width
                          @change="menu = false"
                        >
                        </v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12">
                      <VueEditor
                        v-model="editedItem.description"
                        :editorToolbar="customToolbar"
                      ></VueEditor>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="close">
                  Annuler
                </v-btn>
                <v-btn color="blue darken-1" text @click="save">
                  Enregistrer
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- CLIENTS LIST -->
          <v-dialog v-model="dialogUsers" max-width="500px" scrollable>
            <client-list
              :food="food"
              @close-client-dialog="dialogUsers = false"
            ></client-list>
          </v-dialog>

          <!-- DIALOG DELETE -->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Êtes-vous sûrs de vouloir supprimer cet élément?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="closeDelete"
                  >Annuler</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:[`item.description`]="{ item }">
        <span v-html="item.description"></span>
      </template>

      <!-- INSERT FOOD IMAGE -->
      <template v-slot:[`item.photo`]="{ item }">
        <v-edit-dialog
          :return-value.sync="item.photo"
          large
          @save="updatePhoto(item)"
          save-text="Enregister"
          cancel-text="Annuler"
        >
          <img
            crossorigin="anonymous"
            width="100"
            :src="`${server.url}/food/image/${item.photo}`"
          />
          <template v-slot:input>
            <div class="mt-4 text-h6">Image du repas</div>
            <v-file-input
              v-model="photo"
              label="Ajouter un image"
              truncate-length="15"
            >
            </v-file-input>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <!--<v-icon small @click="openAttribution(item)" class="mr-2">
          mdi-account-plus
        </v-icon>-->
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <!--<v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>-->
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Actualiser </v-btn>
      </template>
    </v-data-table>
  </section>
</template>

<script>
import server from "@/config/address";
import service from "@/services";
import Loader from "@/components/Loader.vue";
import ClientList from "@/components/Admin/Food/ClientList.vue";
import FloatingAlert from "@/components/FloatingAlert.vue";
export default {
  components: { Loader, ClientList, FloatingAlert },
  data() {
    return {
      search: "",
      menu: false,
      loadingTable: false,
      photo: null,
      dialog: false,
      dialogDelete: false,
      dialogUsers: false,
      loading: false,
      alertMessage: "",
      alertShow: false,
      alertType: "",
      valid: false,
      server,
      headers: [
        {
          text: "Intitulé",
          align: "start",
          value: "label",
        },
        { text: "Heure de prise", value: "period" },
        { text: "Catégorie", value: "category.label" },
        { text: "Description", value: "description" },
        { text: "Objectif", value: "objectif" },
        { text: "Intervalle de poids", value: "weightInterval" },
        { text: "Sexe", value: "gender" },
        { text: "Photo", value: "photo", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      foods: [],
      food: {},
      objective: [
        { objectif: "Perte de poids" },
        { objectif: "Prise de masse" },
        { objectif: "Remise en forme" },
      ],
      gender: [{ sexe: "M" }, { sexe: "F" }],
      editedIndex: -1,
      editedItem: {
        label: "",
        category: "",
        description: "",
        period: "",
        weightInterval: [0, 0],
        objective: "",
        gender: "",
      },
      defaultItem: {
        label: "",
        category: "",
        description: "",
        period: "",
        weightInterval: [0, 0],
        objective: "",
        gender: "",
      },
      clients: [],
      foodCategories: [],
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [{ color: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nouveau repas" : "Modifier un repas";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.initialize();
    this.getFoodCategories();
  },
  methods: {
    initialize() {
      this.foods = [];
      this.loadingTable = true;
      service
        .getFoods()
        .then((response) => {
          this.foods = response.data;
          this.loadingTable = false;
        })
        .catch((err) => {
          console.log(err);
          this.loadingTable = false;
        });
    },
    getFoodCategories() {
      service
        .getFoodCategories()
        .then((response) => {
          this.foodCategories = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openAttribution(food) {
      this.food = food;
      this.dialogUsers = true;
    },
    updatePhoto(item) {
      this.loading = true;
      let formData = new FormData();
      formData.append("photo", this.photo);
      formData.append("foodId", item._id);
      service
        .foodImageUpload(formData)
        .then((res) => {
          console.log(res);
          this.initialize();
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    deleteItemConfirm() {
      this.alertShow = false;
      this.loading = true;
      service
        .deleteFood(this.editedItem._id)
        .then((response) => {
          this.foods.splice(this.editedIndex, 1);
          this.closeDelete();
          this.loading = false;
          this.alertMessage = response.data;
          this.alertShow = true;
          this.alertType = "green";
        })
        .catch((err) => {
          this.loading = false;
          this.alertMessage = err;
          this.alertShow = true;
          this.alertType = "red";
        });
    },
    editItem(item) {
      this.editedIndex = this.foods.indexOf(item);
      this.editedItem = JSON.parse(JSON.stringify(item));
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.foods.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      console.log(this.editedItem);
      if (this.editedIndex > -1) {
        this.loading = true;
        service
          .updateFood(this.editedItem._id, this.editedItem)
          .then((response) => {
            console.log(response);
            Object.assign(this.foods[this.editedIndex], this.editedItem);
            this.loading = false;
            this.close();
            this.initialize();
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
      } else {
        this.loading = true;
        service
          .createFood(this.editedItem)
          .then((response) => {
            console.log(response);
            this.close();
            this.initialize();
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style></style>
