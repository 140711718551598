<template>
	<v-row class="mt-5">
		<v-col
			cols="12"
			sm="4"
			v-for="skeletonCard in skeletonCards"
			:key="skeletonCard"
		>
			<v-card rounded="xl">
				<v-card-text>
					<v-skeleton-loader type="article"></v-skeleton-loader>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
export default {
	data() {
		return {
			skeletonCards: [1, 2, 3],
		};
	},
};
</script>

<style>
</style>