import Vue from "vue";
import VueRouter from "vue-router";
import Account from "../views/Account.vue";
import Admin from "../views/Admin.vue";
import Dashboard from "../layouts/Dashboard.vue";
import Clients from "../layouts/Clients.vue";
import Sponsorship from "../layouts/Sponsorship.vue";
import Coach from "../layouts/Coach.vue";
import Subscriptions from "../layouts/Subscriptions.vue";

//Protocols pages
import Protocols from "../layouts/Protocols.vue";
import ProtocolsList from "@/components/Admin/Protocols/List.vue";
import ProtocolsExercise from "@/components/Admin/Protocols/Exercise.vue";

// Meals pages
import Meals from "../layouts/Food.vue";
import MealsList from "@/components/Admin/Food/List.vue";
import Attributions from "@/components/Admin/Food/Attribution.vue";
import MealsCategories from "@/components/Admin/Food/Categories.vue";

//Session pages
import Session from "@/layouts/Session.vue";
import SessionList from "@/components/Admin/Session/List.vue";
import SessionAttributions from "@/components/Admin/Session/Attribution.vue";

//Transactions page
import Transactions from "@/layouts/Transactions.vue";

//Assistance page
import Assistance from "@/layouts/Assistance.vue";

//FAQ page
import Faq from "@/layouts/Faq.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "account",
		component: Account,
	},
	{
		path: "/admin",
		name: "admin",
		component: Admin,
		children: [
			{
				path: "/admin",
				name: "Dashboard",
				component: Dashboard,
			},
			{
				path: "/clients",
				name: "Clients",
				component: Clients,
			},
			{
				path: "/parrainages",
				name: "Sponsorship",
				component: Sponsorship,
			},
			{
				path: "/coachs",
				name: "Coaches",
				component: Coach,
			},
			{
				path: "/abonnements",
				name: "Subscriptions",
				component: Subscriptions,
			},
			{
				path: "/protocoles",
				component: Protocols,
				children: [
					{
						path: "liste",
						name: "protocols-list",
						component: ProtocolsList,
					},
					{
						path: "exercices",
						name: "protocols-exercise",
						component: ProtocolsExercise,
					},
				],
			},
			{
				path: "/repas",
				component: Meals,
				children: [
					{
						path: "liste",
						name: "meals-list",
						component: MealsList,
					},
					{
						path: "attributions",
						name: "meals-attributions",
						component: Attributions,
					},
					{
						path: "categories",
						name: "meals-categories",
						component: MealsCategories,
					},
				],
			},
			{
				path: "/seances",
				component: Session,
				children: [
					{
						path: "liste",
						name: "session-list",
						component: SessionList,
					},
					{
						path: "attributions",
						name: "session-attributions",
						component: SessionAttributions,
					},
				],
			},
			{
				path: "/transactions",
				name: "Transactions",
				component: Transactions,
			},
			{
				path: "/assistance",
				name: "Assistance",
				component: Assistance,
			},
			{
				path: "/faq",
				name: "Faq",
				component: Faq,
			},
		],
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

export default router;
