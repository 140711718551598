<template>
	<v-row>
		<v-col cols="12" sm="12">
			<PresenceGraph />
		</v-col>
		<v-col cols="12" sm="12" md="8">
			<Presence />
		</v-col>
		<v-col cols="12" sm="12" md="4">
			<SubscriptionClients />
		</v-col>
	</v-row>
</template>

<script>
	import Presence from "@/components/Admin/Dashboard/Presence.vue";
	import SubscriptionClients from "@/components/Admin/Dashboard/SubscriptionClients.vue";
	import PresenceGraph from "@/components/Admin/Dashboard/PresenceGraph.vue";

	export default {
		components: { Presence, SubscriptionClients, PresenceGraph },
	};
</script>

<style></style>
