<template>
	<section>
		<card-loading v-if="loading"></card-loading>
		<not-found v-else-if="customers.length === 0"></not-found>
		<v-row v-else>
			<v-col sm="3" v-for="(customer, index) in customers" :key="index">
				<v-card rounded="xl">
					<v-card-title> Parrainage {{ index + 1 }} </v-card-title>
					<v-card-text>
						<div>
							<v-badge
								bordered
								color="#E53935"
								content="P"
								overlap
							>
								<v-avatar size="40" color="#076BC0">
									<img
										v-if="customer.godFather.photo"
										alt="user"
										:src="customer.godFather.photo"
									/>
									<span v-else class="white--text text-h5"
										>{{
											customer.godFather.fullName.charAt(
												0
											)
										}}{{
											customer.godFather.fullName.charAt(
												1
											)
										}}</span
									>
								</v-avatar>
							</v-badge>
							<span class="ml-3">{{
								customer.godFather.fullName
							}}</span>
						</div>
						<v-icon class="text-right" x-large
							>mdi-dots-vertical</v-icon
						>
						<div>
							<v-avatar size="40" color="#076BC0">
								<img
									v-if="customer.photo"
									alt="user"
									:src="customer.photo"
								/>
								<span v-else class="white--text text-h5"
									>{{ customer.fullName.charAt(0)
									}}{{ customer.fullName.charAt(1) }}</span
								>
							</v-avatar>

							<span class="ml-3">{{ customer.fullName }}</span>
						</div>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</section>
</template>

<script>
import service from "../services";
import NotFound from "@/components/Notfound.vue";
import CardLoading from "@/components/CardLoading.vue";
export default {
	components: { NotFound, CardLoading },
	data() {
		return {
			search: "",
			loading: false,
			customers: [],
		};
	},
	created() {
		this.initialize();
	},
	methods: {
		initialize() {
			this.loading = true;
			service
				.getSponsorships()
				.then((response) => {
					this.customers = response.data;

					this.loading = false;
				})
				.catch((err) => {
					console.log(err);
					this.loading = false;
				});
		},
	},
};
</script>

<style>
</style>