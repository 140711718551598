<template>
	<v-row justify="center">
		<v-card class="elevation-20" width="70%" rounded="xl">
			<v-card-text>
				<v-row justify="center">
					<v-col cols="12" sm="10">
						<img
							src="../assets/undraw_no_data_re_kwbl.svg"
							alt="Cez-fitness-notfound-data"
							style="
								display: block;
								margin-left: auto;
								margin-right: auto;
								width: 50%;
							"
						/>
						<p style="text-align: center">
							Aucune information pour le moment. Veuillez
							réessayer plus tard
						</p>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-row>
</template>

<script>
export default {};
</script>

<style>
</style>