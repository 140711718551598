<template>
	<v-card class="elevation-10" rounded="xl">
		<v-card-text>
			<line-chart
				:data="`${server.url}/presence/chart`"
				:curve="false"
				label="Présences"
				:legend="true"
				:messages="{ empty: 'Aucune donnée' }"
			></line-chart>
		</v-card-text>
	</v-card>
</template>

<script>
import server from "@/config/address";
export default {
	props: ["chartData"],
	data() {
		return {
			server,
		};
	},
};
</script>

<style></style>
