<template>
	<section>
		<SideBar />
		<v-container>
			<router-view></router-view>
		</v-container>
	</section>
</template>

<script>
import SideBar from "../layouts/SideBar.vue";
export default {
	components: {
		SideBar,
	},
};
</script>

<style scoped></style>
