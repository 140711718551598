/**
 * Converts meals data into displayable data by the calendar component of Vuetify
 */
function transformMealsData(meals) {
	let mealsData = [];
	meals.forEach((meal, index) => {
		if (meal.customer) {
			mealsData.push({
				start: `${meal.day} ${meal.period}`,
				period: meal.period,
				timed: true,
				color: "blue darken-3",
				name: meal.customer.fullName,
				food: meal.food,
				id: meal._id,
			});
		}
	});
	return mealsData;
}

function transformSessionData(programmedSessions) {
	let data = [];
	programmedSessions.forEach((programmedSession, index) => {
		data.push({
			start: `${programmedSession.createdAt}`,
			timed: true,
			color: "blue darken-3",
			quantity: programmedSession.quantity,
			name: programmedSession.customer.fullName,
			coach: programmedSession.session.user.fullName,
			session: programmedSession.session.label,
			description: programmedSession.session.description,
			id: programmedSession._id,
		});
	});
	return data;
}

export default { transformMealsData, transformSessionData };
