<template>
	<section>
		<v-list v-if="client.subscription">
			<floating-alert-vue
				:message="alertMessage"
				:show="alertShow"
				:type="alertType"
			></floating-alert-vue>
			<loader-vue :dialog="loading"></loader-vue>
			<v-list-item>
				<v-list-item-avatar>
					<v-icon>mdi-playlist-check</v-icon>
				</v-list-item-avatar>

				<v-list-item-content>
					<v-list-item-title
						class="mb-2"
						v-html="client.subscription.label"
					></v-list-item-title>
					Prix:
					{{ client.subscription.price }} FCFA
					<br />
					Durée:
					{{ client.subscription.duration }} jours <br />
					Type: {{ client.subscription.type }} <br />
					Date d'inscription:
					{{ client.inscriptionDate }} <br />
				</v-list-item-content>
				<v-checkbox label="Inscrit" v-model="registered"></v-checkbox>
			</v-list-item>
		</v-list>
		<v-alert type="warning" v-else>Aucun abonnement attribué</v-alert>
	</section>
</template>

<script>
import services from "@/services";
import FloatingAlertVue from "@/components/FloatingAlert.vue";
import LoaderVue from "@/components/Loader.vue";
export default {
	props: ["client"],
	components: { FloatingAlertVue, LoaderVue },
	data() {
		return {
			registered: this.client.registered,
			alertMessage: "",
			alertShow: false,
			alertType: "",
			loading: false,
		};
	},
	watch: {
		registered(checked) {
			this.alertShow = false;
			this.loading = true;
			services
				.updateInscription(this.client._id, checked)
				.then((results) => {
					this.loading = false;
					this.alertMessage = "Inscription modifiée";
					this.alertShow = true;
					this.alertType = "green";
				})
				.catch((err) => {
					console.log(err);
					this.loading = false;
					this.alertMessage = "Echec de l'inscription";
					this.alertShow = true;
					this.alertType = "red";
				});
		}
	},
};
</script>

<style>
</style>