<template>
	<section>
		<loader :dialog="loading"></loader>
		<floating-alert :message="alertMessage" :show="alertShow" :type="alertType"></floating-alert>
		<v-container>
			<v-row>
				<v-text-field label="Rechercher" append-icon="mdi-magnify" v-model="search"></v-text-field>
				<v-spacer></v-spacer>
				<v-spacer></v-spacer>
				<v-dialog v-model="dialog" max-width="500px">
					<template v-slot:activator="{ on, attrs }">
						<v-btn color="red" dark rounded v-bind="attrs" v-on="on">
							<v-icon>mdi-plus</v-icon> Nouveau
						</v-btn>
					</template>
					<v-card>
						<v-card-title>
							<span class="text-h5">{{ formTitle }}</span>
						</v-card-title>

						<v-card-text>
							<v-container>
								<v-row>
									<v-col cols="12">
										<v-text-field v-model="category.label"
											label="Nom de la catégorie"></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field v-model="category.description" label="Description"></v-text-field>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="red darken-1" text @click="close">
								Annuler
							</v-btn>
							<v-btn color="blue darken-1" text @click="save">
								Enregistrer
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<!-- DIALOG DELETE -->
				<v-dialog v-model="dialogDelete" max-width="500px">
					<v-card>
						<v-card-title class="text-h5">Voulez-vous supprimer cette
							catégorie?</v-card-title>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="red darken-1" text @click="closeDelete">Annuler</v-btn>
							<v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
							<v-spacer></v-spacer>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-row>
		</v-container>
		<card-loading v-if="skeletonLoader"></card-loading>
		<not-found v-else-if="categories.length === 0"></not-found>
		<v-row class="mt-5" v-else>
			<v-col sm="4" md="6" v-for="category in filteredCategories" :key="category._id">
				<v-card class="mx-auto rounded-xl" max-width="344" outlined>
					<v-list-item three-line>
						<v-list-item-content>
							<v-list-item-title class="text-h5 mb-1">
								{{ category.label }}
							</v-list-item-title>
							<v-list-item-subtitle>{{
								category.description
							}}</v-list-item-subtitle>
						</v-list-item-content>

						<v-list-item-avatar tile size="80" color="grey">
							<v-img src="../../../assets/image-categorie.jpg"></v-img>
						</v-list-item-avatar>
					</v-list-item>

					<v-card-actions>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn class="mr-2" outlined icon v-bind="attrs" v-on="on" @click="editItem(category)">
									<v-icon>mdi-pencil</v-icon>
								</v-btn>
							</template>
							<span>Modifier</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn outlined icon color="red" v-bind="attrs" v-on="on" @click="deleteItem(category)">
									<v-icon>mdi-delete</v-icon>
								</v-btn>
							</template>
							<span>Supprimer</span>
						</v-tooltip>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</section>
</template>

<script>
import services from "@/services";
import Loader from "@/components/Loader.vue";
import NotFound from "@/components/Notfound.vue";
import CardLoading from "@/components/CardLoading.vue";
import FloatingAlert from "@/components/FloatingAlert.vue";
export default {
	components: { Loader, NotFound, CardLoading, FloatingAlert },
	data() {
		return {
			category: {
				label: "",
				description: "",
			},
			defaultItemCategory: {
				label: "",
				description: "",
			},
			loading: false,
			skeletonLoader: false,
			categories: [],
			editedIndex: -1,
			dialog: false,
			dialogDelete: false,
			alertMessage: "",
			alertShow: false,
			alertType: "",
			search: "",
		};
	},
	computed: {
		formTitle() {
			return this.editedIndex === -1
				? "Nouvelle catégorie"
				: "Modifier une catégorie";
		},
		filteredCategories() {
			return this.categories.filter((category) => {
				return category.label
					.toLowerCase()
					.includes(this.search.toLowerCase());
			});
		},
	},
	mounted() {
		this.initialize();
	},
	methods: {
		initialize() {
			this.categories = [];
			this.skeletonLoader = true;
			services
				.getFoodCategories()
				.then((results) => {
					this.skeletonLoader = false;
					this.categories = results.data;
				})
				.catch((err) => {
					console.log(err);
					this.skeletonLoader = false;
				});
		},
		save() {
			if (this.editedIndex > -1) {
				this.loading = true;
				services
					.updateFoodCategory(this.category._id, this.category)
					.then((response) => {
						console.log(response);
						Object.assign(
							this.categories[this.editedIndex],
							this.category
						);
						this.loading = false;
						this.close();
						this.initialize();
					})
					.catch((err) => {
						console.log(err);
						this.loading = false;
					});
			} else {
				this.loading = true;
				services
					.createFoodCategory(this.category)
					.then((response) => {
						console.log(response);
						this.close();
						this.initialize();
						this.loading = false;
					})
					.catch((err) => {
						console.log(err);
						this.loading = false;
					});
			}
		},
		editItem(item) {
			this.editedIndex = this.categories.indexOf(item);
			this.category = Object.assign({}, item);
			this.dialog = true;
		},

		deleteItem(item) {
			this.editedIndex = this.categories.indexOf(item);
			this.category = Object.assign({}, item);
			this.dialogDelete = true;
		},
		deleteItemConfirm() {
			this.alertShow = false;
			this.loading = true;
			services
				.deleteFoodCategory(this.category._id)
				.then((response) => {
					this.categories.splice(this.editedIndex, 1);
					this.closeDelete();
					this.loading = false;
					this.alertMessage = response.message;
					this.alertShow = true;
					this.alertType = "green";
				})
				.catch((err) => {
					this.loading = false;
					this.alertMessage =
						"Impossible de supprimer la catégorie";
					this.alertShow = true;
					this.alertType = "red";
				});
		},
		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.category = Object.assign({}, this.defaultItemCategory);
				this.editedIndex = -1;
			});
		},

		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.category = Object.assign({}, this.defaultItemCategory);
				this.editedIndex = -1;
			});
		},
	},
};
</script>

<style></style>
