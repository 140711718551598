<template>
	<pie-chart
		:data="`${server.url}/subscription/chart/customer`"
		:messages="{empty: 'Aucune donnnée'}"
		:colors="['blue', 'red', 'white', 'black']"
	></pie-chart>
</template>

<script>
	import server from "@/config/address";
	export default {
		name: "Subscription-clients",
		data(){
			return {
				server
			}
		}
	};
</script>

<style></style>
