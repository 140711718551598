<template>
	<div class="text-center">
		<v-dialog v-model="loadingStatus" hide-overlay persistent width="300">
			<v-card color="primary" dark>
				<v-card-text>
					Chargement en cours...
					<v-progress-linear
						indeterminate
						color="white"
						class="mb-0"
					></v-progress-linear>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	export default {
		name: "Loader",
		props: ["dialog"],
		computed: {
			loadingStatus() {
				if (this.dialog == true) {
					return true;
				} else {
					return false;
				}
			},
		},
	};
</script>
