<template>
	<section>
		<loader :dialog="loading"></loader>
		<floating-alert :message="alertMessage" :show="alertShow" :type="alertType"></floating-alert>
		<v-container>
			<v-row>
				<v-text-field label="Rechercher" append-icon="mdi-magnify" v-model="search"></v-text-field>
				<v-spacer></v-spacer>
				<v-spacer></v-spacer>
				<v-dialog v-model="dialogAddCoach" max-width="500px">
					<template v-slot:activator="{ on, attrs }">
						<v-btn color="red" dark rounded v-bind="attrs" v-on="on">
							<v-icon>mdi-plus</v-icon> Nouveau
						</v-btn>
					</template>
					<v-card>
						<v-card-title>
							<span class="text-h5">Ajouter un coach</span>
						</v-card-title>

						<v-card-text>
							<v-container>
								<v-form ref="form" v-model="valid" lazy-validation>

									<v-row>
										<v-col cols="12">
											<v-text-field :rules="rules" v-model="coach.fullName"
												label="Nom complet"></v-text-field>
										</v-col>
										<v-col cols="6">
											<v-text-field :rules="rules" v-model="coach.cni"
												label="N° CNI"></v-text-field>
										</v-col>
										<v-col cols="6">
											<v-text-field :rules="rules" v-model="coach.phone"
												label="Téléphone"></v-text-field>
										</v-col>
										<v-col cols="12">
											<v-text-field :rules="rules" v-model="coach.email"
												label="Adresse email"></v-text-field>
										</v-col>
										<v-col cols="12">
											<v-text-field :rules="passwordRules" v-model="coach.password"
												label="Mot de passe"></v-text-field>
										</v-col>
									</v-row>
								</v-form>
							</v-container>
						</v-card-text>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="red darken-1" text @click="dialogAddCoach = false;">
								Annuler
							</v-btn>
							<v-btn :disabled="!valid" color="blue darken-1" text @click="save">
								Enregistrer
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<!-- DIALOG DELETE -->
				<!-- <v-dialog v-model="dialogDelete" max-width="500px">
					<v-card>
						<v-card-title class="text-h5">Voulez-vous supprimer cette
							catégorie?</v-card-title>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="red darken-1" text @click="closeDelete">Annuler</v-btn>
							<v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
							<v-spacer></v-spacer>
						</v-card-actions>
					</v-card>
				</v-dialog> -->
			</v-row>

			<section>
				<card-loading v-if="loading"></card-loading>
				<not-found v-else-if="coaches.length === 0"></not-found>
				<v-row v-else>
					<v-col cols="12" sm="4" v-for="coach in coaches" :key="coach._id">
						<v-card class="elevation-10" style="max-width: 400px" rounded="xl">
							<v-row justify="space-between">
								<v-col cols="8">
									<v-card-title>
										<div>
											<div class="text-h5">
												{{ coach.fullName }}
											</div>
										</div>
									</v-card-title>
								</v-col>
								<v-col cols="4">
									<v-avatar size="50" color="#076BC0">
										<img v-if="coach.photo" alt="user" :src="coach.photo" />
										<span v-else class="white--text text-h5">{{ coach.fullName.charAt(0)
											}}{{ coach.fullName.charAt(1) }}</span>
									</v-avatar>
								</v-col>
							</v-row>
							<v-divider></v-divider>
							<v-card-actions class="pa-4">
								<v-spacer></v-spacer>
								<span class="text-caption mr-2">
									({{ coach.generalNote }})
								</span>
								<v-rating readonly :value="coach.generalNote" color="#076BC0" dense half-increments
									size="30"></v-rating>
							</v-card-actions>
						</v-card>
					</v-col>
				</v-row>
			</section>
		</v-container>
	</section>
</template>

<script>
import service from "@/services";
import Loader from "@/components/Loader.vue";
import FloatingAlert from "@/components/FloatingAlert.vue";
export default {
	components: { Loader, FloatingAlert },
	data() {
		return {
			valid: true,
			loading: false,
			dialogAddCoach: false,
			search: "",
			coaches: [],
			coach: {
				fullName: "",
				cni: "",
				phone: "",
				email: "",
				password: "",
				role: "COACH"
			},
			defaultItemCoach: {
				fullName: "",
				cni: "",
				phone: "",
				email: "",
				password: "",
				role: "COACH"
			},
			alertMessage: "",
			alertShow: null,
			alertType: "",
			rules: [(v) => !!v || "Veuillez remplir ce champ",
			],
			passwordRules: [(v) => !!v || "Veuillez remplir ce champ",
			(v) =>
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/.test(v) ||
				"Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule et un chiffre"
			],
		};
	},
	created() {
		this.initialize();
	},
	methods: {
		manageAlert(display, type, message) {
			this.alertShow = display;
			this.alertType = type;
			this.alertMessage = message;
		},
		initialize() {
			this.loading = true;
			service
				.getSpecificUsers("COACH")
				.then((response) => {
					this.coaches = response.data;

					this.loading = false;
				})
				.catch((err) => {
					console.log(err);
					this.loading = false;
				});
		},
		save() {
			this.alertShow = false;
			if (this.$refs.form.validate()) {
				this.loading = true;
				service
					.register(
						this.coach
					)
					.then((response) => {
						this.loading = false;
						this.dialogAddCoach = false;
						this.manageAlert(true, "success", "Paiement ajouté");
						this.coach = this.defaultItemCoach;
						this.initialize();
					})
					.catch((err) => {
						console.log(err);
						this.loading = false;
						this.manageAlert(
							true,
							"error",
							err.message
						);
					});
			}
		}
	},
};
</script>

<style></style>