<template>
	<section>
		<loader :dialog="loading"></loader>
		<floating-alert
			:message="alertMessage"
			:show="alertShow"
			:type="alertType"
		></floating-alert>
		<v-row class="fill-height">
			<v-col>
				<v-sheet height="64" class="rounded-t-xl">
					<v-toolbar flat class="rounded-t-xl">
						<v-btn
							outlined
							class="mr-4"
							color="grey darken-2"
							@click="setToday"
						>
							Aujourd'hui
						</v-btn>
						<v-btn
							fab
							text
							small
							color="grey darken-2"
							@click="prev"
						>
							<v-icon small> mdi-chevron-left </v-icon>
						</v-btn>
						<v-btn
							fab
							text
							small
							color="grey darken-2"
							@click="next"
						>
							<v-icon small> mdi-chevron-right </v-icon>
						</v-btn>
						<v-toolbar-title v-if="$refs.calendar">
							{{ $refs.calendar.title }}
						</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-menu bottom right>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									outlined
									color="grey darken-2"
									v-bind="attrs"
									v-on="on"
								>
									<span>{{ typeToLabel[type] }}</span>
									<v-icon right> mdi-menu-down </v-icon>
								</v-btn>
							</template>
							<v-list>
								<v-list-item @click="type = 'day'">
									<v-list-item-title>Jour</v-list-item-title>
								</v-list-item>
								<v-list-item @click="type = 'week'">
									<v-list-item-title
										>Semaine</v-list-item-title
									>
								</v-list-item>
								<v-list-item @click="type = 'month'">
									<v-list-item-title>Mois</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
						<!-- DIALOG DELETE -->
						<v-dialog v-model="dialogDelete" max-width="500px">
							<v-card>
								<v-card-title class="text-h5"
									>Voulez-vous supprimer cette
									attribution?</v-card-title
								>
								<v-card-actions>
									<v-spacer></v-spacer>
									<v-btn
										color="red darken-1"
										text
										@click="closeDelete"
										>Annuler</v-btn
									>
									<v-btn
										color="blue darken-1"
										text
										@click="deleteItemConfirm"
										>OK</v-btn
									>
									<v-spacer></v-spacer>
								</v-card-actions>
							</v-card>
						</v-dialog>
					</v-toolbar>
				</v-sheet>
				<v-sheet height="470">
					<v-calendar
						ref="calendar"
						v-model="focus"
						color="red"
						event-more-text="VOIR PLUS"
						locale="fr-FR"
						:events="foods"
						:event-color="getEventColor"
						:type="type"
						@click:event="showEvent"
						@click:more="viewDay"
						@click:date="viewDay"
					></v-calendar>
					<v-menu
						v-model="selectedOpen"
						:close-on-content-click="false"
						:activator="selectedElement"
						offset-x
					>
						<v-card color="grey lighten-4" min-width="350px" flat>
							<v-toolbar :color="selectedEvent.color" dark>
								<h3
									v-html="
										selectedEvent.name +
										' - ' +
										selectedEvent.period
									"
								></h3>
								<v-spacer></v-spacer>
								<v-btn
									icon
									color="error"
									@click="deleteItem(selectedEvent)"
								>
									<v-icon>mdi-delete</v-icon>
								</v-btn>
							</v-toolbar>
							<v-card-text>
								<div v-if="selectedEvent.food">
									<span
										v-html="selectedEvent.food.label"
									></span>
									<span
										v-html="selectedEvent.food.description"
									></span>
								</div>
							</v-card-text>
							<v-card-actions>
								<v-btn
									text
									color="error"
									@click="selectedOpen = false"
								>
									Fermer
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-menu>
				</v-sheet>
			</v-col>
		</v-row>
	</section>
</template>

<script>
import Loader from "@/components/Loader.vue";
import service from "@/services";
import calendarData from "@/helpers/Calendar-data";
import FloatingAlert from "@/components/FloatingAlert.vue";
export default {
	components: { Loader, FloatingAlert },
	data: () => ({
		dialog: false,
		dialogDelete: false,
		dialogUsers: false,
		loading: false,
		alertMessage: "",
		alertShow: false,
		alertType: "",
		foods: [],
		// calendar data
		focus: "",
		type: "month",
		typeToLabel: {
			month: "Mois",
			week: "Semaines",
			day: "Jours",
		},
		selectedEvent: {},
		selectedElement: null,
		selectedOpen: false,
		editedIndex: -1,
		colors: ["grey darken-2"],
	}),
	created() {
		this.initialize();
	},
	methods: {
		initialize() {
			this.loading = true;
			service
				.getFoodsAttributions()
				.then((response) => {
					this.foods = calendarData.transformMealsData(response.data);
					this.loading = false;
				})
				.catch((err) => {
					console.log(err);
					this.loading = false;
				});
		},

		viewDay({ date }) {
			this.focus = date;
			this.type = "day";
		},
		getEventColor(event) {
			return event.color;
		},
		setToday() {
			this.focus = "";
		},
		prev() {
			this.$refs.calendar.prev();
		},
		next() {
			this.$refs.calendar.next();
		},
		showEvent({ nativeEvent, event }) {
			const open = () => {
				this.selectedEvent = event;
				this.selectedElement = nativeEvent.target;
				requestAnimationFrame(() =>
					requestAnimationFrame(() => (this.selectedOpen = true))
				);
			};

			if (this.selectedOpen) {
				this.selectedOpen = false;
				requestAnimationFrame(() =>
					requestAnimationFrame(() => open())
				);
			} else {
				open();
			}

			nativeEvent.stopPropagation();
		},
		deleteItem(item) {
			this.editedIndex = this.foods.indexOf(item);
			// this.category = Object.assign({}, item);
			this.dialogDelete = true;
		},
		deleteItemConfirm() {
			this.alertShow = false;
			this.loading = true;
			service
				.removeAttribution(this.selectedEvent.id)
				.then((response) => {
					this.foods.splice(this.editedIndex, 1);
					this.closeDelete();
					this.loading = false;
					this.alertMessage = response.message;
					this.alertShow = true;
					this.alertType = "green";
				})
				.catch((err) => {
					this.loading = false;
					this.alertMessage = "Impossible de supprimer l'attribution";
					this.alertShow = true;
					this.alertType = "red";
				});
		},
		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				// this.category = Object.assign({}, this.defaultItemCategory);
				this.editedIndex = -1;
			});
		},
	},
};
</script>

<style>
</style>