<template>
	<section>
		<loader :dialog="loading"></loader>
		<v-row class="justify-end">
			<v-dialog v-model="dialog" width="500" scrollable>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						small
						fab
						color="red darken-1"
						dark
						v-bind="attrs"
						v-on="on"
						><v-icon large>mdi-plus</v-icon></v-btn
					>
				</template>

				<v-card>
					<v-card-title class="text-h5 grey lighten-2">
						Nouvelle récompense
					</v-card-title>

					<v-card-text>
						<v-container>
							<v-row>
								<v-col cols="12" sm="12">
									<v-text-field
										v-model="reward.label"
										label="Intitulé"
										hide-details=""></v-text-field>
								</v-col>
								<v-col cols="12" sm="12">
									<label for="">Nombre de points</label>
									<vue-number-input
										v-model="reward.point"
										center
										size="small"
										controls></vue-number-input>
								</v-col>
							</v-row>
						</v-container>
					</v-card-text>

					<v-divider></v-divider>

					<v-card-actions>
						<v-btn color="error" text @click="dialog = false">
							Annuler
						</v-btn>
						<v-spacer></v-spacer>
						<v-btn color="primary" text @click="addReward">
							Enregistrer
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-row>
		<v-list three-line>
			<template>
				<v-list-item
					v-for="(reward, index) in rewards"
					:key="index"
					@click="attributeReward(reward.point)">
					<v-list-item-avatar>
						<v-icon>mdi-trophy</v-icon>
					</v-list-item-avatar>

					<v-list-item-content>
						<v-list-item-title
							><span class="font-weight-bold">Intitulé: </span
							>{{ reward.label }}</v-list-item-title
						>
						<v-list-item-subtitle
							><span class="font-weight-bold">Points: </span>
							{{ reward.point }}</v-list-item-subtitle
						>
					</v-list-item-content>
				</v-list-item>
			</template>
		</v-list>
	</section>
</template>

<script>
	import Loader from "@/components/Loader.vue";
	import service from "@/services";
	export default {
		props: ["rewards", "customerId"],
		data() {
			return {
				dialog: false,
				loading: false,
				reward: {
					point: 0,
					label: "",
				},
			};
		},
		components: { Loader },
		methods: {
			attributeReward(points) {
				this.loading = true;
				service
					.attributeReward(this.customerId, points)
					.then((response) => {
						console.log(response);
						this.$emit("close-reward-dialog");
						this.loading = false;
					})
					.catch((err) => {
						console.log(err);
						this.loading = false;
						this.dialog = false;
					});
			},
			addReward() {
				this.loading = true;
				service
					.addReward(this.reward)
					.then((response) => {
						console.log(response);
						this.dialog = false;
						this.loading = false;
					})
					.catch((err) => {
						console.log(err);
						this.loading = false;
					});
			},
		},
	};
</script>

<style></style>
