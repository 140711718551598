<template>
  	<section>
		<v-list v-if="foods.length >= 1">
			<v-list-item v-for="(item, index) in foods" :key="index">
				<v-list-item-avatar>
					<img
						crossorigin="anonymous"
						:src="`${server.url}/food/image/${item.food.photo}`"
					/>
				</v-list-item-avatar>

				<v-list-item-content>
					<v-list-item-title
						class="mb-2 text-h5"
						v-html="item.food.label"
					></v-list-item-title>
					<span v-html="`Description:
					${item.food.description }`"></span>
					
					<br>
					Crée le:
					{{ item.food.createdAt }}
					<br />
				</v-list-item-content>
			</v-list-item>
		</v-list>
		<v-alert type="warning" v-else>Aucun repas attribué</v-alert>
	</section>
</template>

<script>
	import server from "@/config/address"
    export default {
        props: ["foods"],
        data(){
            return {
                server
            }
        }
    }
</script>

<style>

</style>