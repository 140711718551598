<template>
	<v-row style="" id="form" v-if="formShow">
		<loader :dialog="loading"></loader>
		<v-col cols="12" sm="12">
			<v-form ref="form" v-model="valid" lazy-validation>
				<v-text-field v-model="phone" :counter="9" :rules="phoneRules" label="Numéro de téléphone" required
					rounded outlined dense></v-text-field>

				<v-text-field v-model="password" :rules="passwordRules" label="Mot de passe" required rounded outlined
					dense :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" @click:append="() => (show = !show)"
					:type="show ? 'password' : 'text'"></v-text-field>
				<v-btn :disabled="!valid" color="primary" @click="validate" rounded outlined style="width: 100%">
					Se connecter
				</v-btn>
			</v-form>
			<v-alert class="mt-3" type="error" dense v-if="alert">{{ alertMessage }}</v-alert>
		</v-col>
		<v-col>
			<v-divider></v-divider>
		</v-col>
		<p style="font-size: 13px">
			Vous n'avez pas de compte?
			<v-btn color="primary" x-small text @click="removeForm()">Créez votre compte</v-btn>
		</p>
	</v-row>
</template>

<script>
import service from "@/services";
import Loader from "../Loader.vue";
export default {
	components: { Loader },
	props: ["formShow"],
	data: () => ({
		show: true,
		alertMessage: "",
		alert: false,
		valid: true,
		phone: "",
		loading: false,
		phoneRules: [
			(v) => !!v || "Entrez un numéro de téléphone",
			(v) =>
				(v && v.length == 9) ||
				"Le numéro de téléphone doit avoir 9 chiffres",
		],
		password: "",
		passwordRules: [
			(v) => !!v || "Entrez votre mot de passe",
			(v) =>
				(v && v.length >= 8) ||
				"Le mot de passe doit avoir au moins 8 caractères",
		],
	}),

	methods: {
		validate() {
			if (this.$refs.form.validate()) {
				this.alert = false;
				this.loading = true;
				service
					.login({ phone: this.phone, password: this.password })
					.then((results) => {

						this.loading = false;
						this.$router.push("/admin");
					})
					.catch((err) => {
						this.alert = true;
						this.alertMessage = err ? err.message : "Oops! Veuillez vérifier votre connexion internet"
						this.loading = false;
					});
			}
		},
		removeForm() {
			this.$emit("changeForm");
		},
	},
};
</script>

<style scoped>
#form {
	margin-top: 5%;
	margin-left: 15%;
	margin-right: 15%;
	font-family: Montserrat;
}
</style>
