<template>
	<section>
		<loader :dialog="loading"></loader>
		<floating-alert :message="alertMessage" :show="alertShow" :type="alertType"></floating-alert>
		<v-data-table :loading="loadingTable" loading-text="Chargement en cours..." :headers="headers" :items="sessions"
			:footer-props="{
				'items-per-page-all-text': 'Tout',
				'items-per-page-text': 'Lignes par page',
			}" :search="search" class="elevation-0 pa-2 rounded-xl">
			<template v-slot:top>
				<v-toolbar flat>
					<v-toolbar-title>Liste des séances</v-toolbar-title>
					<v-divider class="mx-4" inset vertical></v-divider>
					<v-spacer></v-spacer>
					<v-text-field v-model="search" append-icon="mdi-magnify" label="Rechercher"
						hide-details></v-text-field>
					<v-spacer></v-spacer>
					<v-dialog v-model="dialog" max-width="500px" scrollable>
						<template v-slot:activator="{ on, attrs }">
							<v-btn color="red" dark class="mb-2 rounded-xl" v-bind="attrs" v-on="on">
								<v-icon>mdi-plus</v-icon> Nouveau
							</v-btn>
						</template>
						<v-card>
							<v-card-title>
								<span class="text-h5">{{ formTitle }}</span>
							</v-card-title>

							<v-card-text>
								<v-container>
									<v-row>
										<v-col cols="12">
											<!-- <v-text-field v-model="editedItem.coach" label="Nom du coach"
												hide-details=""></v-text-field> -->
											<v-select :items="coaches" item-text="fullName" item-value="_id"
												v-model="editedItem.coachId" label="Coach" hide-details=""></v-select>
										</v-col>
										<v-col cols="12" sm="12" md="6">
											<v-text-field v-model="editedItem.label" label="Intitulé"
												hide-details=""></v-text-field>
										</v-col>
										<v-col cols="12" sm="12" md="6">
											<p>Prix</p>
											<vue-number-input v-model="editedItem.price" center size="small" :min="0"
												controls></vue-number-input>
										</v-col>
										<v-col cols="12">
											Description
											<VueEditor v-model="editedItem.description" :editorToolbar="customToolbar">
											</VueEditor>
										</v-col>
									</v-row>
								</v-container>
							</v-card-text>

							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="red darken-1" text @click="close">
									Annuler
								</v-btn>
								<v-btn color="blue darken-1" text @click="save">
									Enregistrer
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>

					<!-- CLIENTS LIST -->
					<v-dialog v-model="attributionDialog" max-width="400px" scrollable>
						<v-card>
							<v-card-title>Attribution de la séance
								<v-spacer></v-spacer>
								<v-btn @click="attributionDialog = false" icon><v-icon>mdi-close-circle</v-icon></v-btn>
							</v-card-title>
							<v-card-text>
								<v-row>
									<v-col cols="12">
										<v-text-field v-model="session.label" label="Nom de la séance" disabled
											hide-details=""></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-select :items="clients" item-text="fullName" item-value="_id"
											v-model="attribution.customerId" label="Client"></v-select>
									</v-col>
									<v-col cols="12">
										Quantité
										<vue-number-input v-model="attribution.sessionQuantity
											" center size="small" controls></vue-number-input>
									</v-col>

									<v-btn color="error" text @click="attributionDialog = false">Annuler</v-btn>
									<v-btn color="primary" text @click="saveProgrammedSession">Confirmer</v-btn>
								</v-row>
							</v-card-text>
						</v-card></v-dialog>

					<!-- DIALOG DELETE -->
					<v-dialog v-model="dialogDelete" max-width="500px">
						<v-card>
							<v-card-title class="text-h5">Êtes-vous sûrs de vouloir supprimer cette
								séance?</v-card-title>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="red darken-1" text @click="closeDelete">Annuler</v-btn>
								<v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
								<v-spacer></v-spacer>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</v-toolbar>
			</template>
			<template v-slot:[`item.description`]="{ item }">
				<span v-html="item.description"></span>
			</template>
			<template v-slot:[`item.status`]="{ item }">
				<v-chip v-if="item.status == 'Active'" color="green" dark>
					En cours
				</v-chip>
				<v-chip v-else color="red" dark>
					Inactif
				</v-chip>
			</template>
			<template v-slot:[`item.actions`]="{ item }">
				<v-icon small @click="sessionAttribution(item)" class="mr-2">
					mdi-account-plus
				</v-icon>
				<v-icon small class="mr-2" @click="editItem(item)">
					mdi-pencil
				</v-icon>
				<v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
			</template>
			<template v-slot:[`footer.page-text`]="items">
				{{ items.pageStart }} - {{ items.pageStop }} de
				{{ items.itemsLength }}
			</template>
			<template v-slot:no-data>
				<v-btn color="primary" @click="initialize"> Actualiser </v-btn>
			</template>
		</v-data-table>
	</section>
</template>

<script>
import server from "@/config/address";
import service from "@/services";
import Loader from "@/components/Loader.vue";
import FloatingAlert from "@/components/FloatingAlert.vue";
export default {
	components: { Loader, FloatingAlert },
	data() {
		return {
			search: "",
			loadingTable: false,
			photo: null,
			dialog: false,
			dialogDelete: false,
			attributionDialog: false,
			loading: false,
			alertMessage: "",
			alertShow: false,
			alertType: "",
			server,
			headers: [
				{
					text: "Intitulé",
					align: "start",
					value: "label",
				},
				{ text: "Description", value: "description" },
				{ text: "Coach associé", value: "user.fullName" },
				{ text: "Prix", value: "price" },
				{ text: "Statut", value: "status" },
				{ text: "Date de création", value: "createdAt" },
				{ text: "Actions", value: "actions", sortable: false },
			],
			sessions: [],
			session: {},
			editedIndex: -1,
			editedItem: {
				label: "",
				description: "",
				coachId: "",
				price: 0
			},
			attribution: {
				sessionId: "",
				customerId: "",
				sessionQuantity: 1,
			},
			defaultItem: {
				label: "",
				description: "",
				coachId: "",
				price: 0
			},
			clients: [],
			coaches: [],
			customToolbar: [
				[{ header: [false, 1, 2, 3, 4, 5, 6] }],
				["bold", "italic", "underline"],
				[{ color: [] }],
				[{ list: "ordered" }, { list: "bullet" }],
			],
		};
	},
	computed: {
		formTitle() {
			return this.editedIndex === -1
				? "Nouvelle séance"
				: "Modifier une séance";
		},
	},
	watch: {
		dialog(val) {
			val || this.close();
		},
		dialogDelete(val) {
			val || this.closeDelete();
		},
	},
	created() {
		this.initialize();
		this.getAllCoaches();
		this.getClients();
	},
	methods: {
		initialize() {
			this.sessions = [];
			this.loadingTable = true;
			service
				.getSessions()
				.then((response) => {
					this.sessions = response.data.sessions;
					this.loadingTable = false;
				})
				.catch((err) => {
					console.log(err);
					this.loadingTable = false;
				});
		},
		getAllCoaches() {
			service
				.getSpecificUsers("COACH")
				.then((response) => {
					this.coaches = response.data;
				})
				.catch((err) => {
					console.log(err);
				});
		},
		getClients() {
			service
				.getClients()
				.then((response) => {
					this.clients = response.data;
				})
				.catch((err) => {
					console.log(err);
				});
		},
		sessionAttribution(session) {
			this.session = session;
			this.attribution.sessionId = session._id;
			this.attributionDialog = true;
		},
		saveProgrammedSession() {
			this.alertShow = false;
			this.loading = true;
			service
				.attributeSession(this.attribution)
				.then((results) => {
					this.alertMessage = results.message;
					this.alertShow = true;
					this.alertType = "green";
				})
				.catch((err) => {
					console.log(err);
					this.alertMessage = err;
					this.alertShow = true;
					this.alertType = "red";
				})
				.finally(() => {
					this.loading = false;
					this.attributionDialog = false;
				});
		},
		deleteItemConfirm() {
			this.alertShow = false;
			this.loading = true;
			service
				.deleteSession(this.editedItem._id)
				.then((response) => {
					this.sessions.splice(this.editedIndex, 1);
					this.closeDelete();
					this.loading = false;
					this.alertMessage = response.message;
					this.alertShow = true;
					this.alertType = "green";
				})
				.catch((err) => {
					this.loading = false;
					this.alertMessage = err.message;
					this.alertShow = true;
					this.alertType = "red";
				});
		},
		editItem(item) {
			this.editedIndex = this.sessions.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialog = true;
		},

		deleteItem(item) {
			this.editedIndex = this.sessions.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},
		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},
		save() {
			if (this.editedIndex > -1) {
				this.loading = true;
				service
					.updateSession(this.editedItem._id, this.editedItem)
					.then((response) => {
						console.log(response);
						Object.assign(
							this.sessions[this.editedIndex],
							this.editedItem
						);
						this.loading = false;
						this.close();
						this.initialize();
					})
					.catch((err) => {
						console.log(err);
						this.loading = false;
					});
			} else {
				this.loading = true;
				service
					.addSession(this.editedItem)
					.then((response) => {
						console.log(response);
						this.close();
						this.initialize();
						this.loading = false;
					})
					.catch((err) => {
						console.log(err);
						this.loading = false;
					});
			}
		},
	},
};
</script>

<style></style>