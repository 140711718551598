<template>
	<v-container>
		<loader :dialog="loading"></loader>
		<floating-alert
			:message="alertMessage"
			:show="alertShow"
			:type="alertType"></floating-alert>
		<!-- Add and update a Question/Answer -->
		<v-row class="ma-3">
			<v-toolbar-title>Liste des Questions/Réponses</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-dialog v-model="dialog" max-width="500px">
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						v-bind="attrs"
						v-on="on"
						class="mb-5 justify-right"
						color="red"
						dark
						rounded
						><v-icon>mdi-plus</v-icon>Nouveau</v-btn
					>
				</template>
				<v-card>
					<v-card-title>
						<span class="text-h5">{{ formTitle }}</span>
					</v-card-title>

					<v-card-text>
						<v-container>
							<v-row>
								<v-col cols="12">
									<v-text-field
										v-model="questionAnswer.question"
										label="Question"></v-text-field>
								</v-col>
								<v-col cols="12">
									<v-text-field
										v-model="questionAnswer.answer"
										label="Réponse"></v-text-field>
								</v-col>
							</v-row>
						</v-container>
					</v-card-text>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="red darken-1" text @click="close">
							Annuler
						</v-btn>
						<v-btn color="blue darken-1" text @click="save">
							Enregistrer
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-row>

		<!-- Delete a Question/Answer -->
		<v-dialog v-model="dialogDelete" max-width="500px">
			<v-card>
				<v-card-title class="text-h5"
					>Êtes-vous sûrs de vouloir supprimer?</v-card-title
				>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="red darken-1" text @click="closeDelete"
						>Annuler</v-btn
					>
					<v-btn color="blue darken-1" text @click="deleteItemConfirm"
						>Confirmer</v-btn
					>
					<v-spacer></v-spacer>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Questions/Answers list -->
		<div v-if="questions.length === 0">
			<not-found></not-found>
			<v-row class="justify-center">
				<v-btn
					color="red"
					dark
					rounded
					class="mt-5"
					@click="initialize">
					<v-icon>mdi-refresh</v-icon>
					Actualiser</v-btn
				>
			</v-row>
		</div>
		<v-expansion-panels v-else>
			<v-expansion-panel v-for="(item, i) in questions" :key="i">
				<v-expansion-panel-header class="text-h5 red--text">
					{{ item.question }}
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					{{ item.answer }}
					<div class="mt-5">
						<v-btn
							class="mr-3"
							rounded
							outlined
							color="primary"
							@click="editItem(item)">
							<v-icon left>mdi-pencil</v-icon>
							Modifier
						</v-btn>
						<v-btn
							outlined
							rounded
							color="error"
							@click="deleteItem(item)">
							<v-icon left>mdi-delete</v-icon>
							Supprimer
						</v-btn>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</v-container>
</template>

<script>
	import services from "../services";
	import Loader from "@/components/Loader.vue";
	import NotFound from "@/components/Notfound.vue";
	import FloatingAlert from "@/components/FloatingAlert.vue";
	export default {
		components: { Loader, NotFound, FloatingAlert },
		data: () => ({
			questions: [],
			loading: false,
			dialog: false,
			dialogDelete: false,
			alertMessage: "",
			alertShow: false,
			alertType: "",
			questionAnswer: {
				question: "",
				answer: "",
			},
			defaultQuestionAnswer: {
				question: "",
				answer: "",
			},
			editedIndex: -1,
		}),
		computed: {
			formTitle() {
				return this.editedIndex === -1
					? "Nouvelle Question/Réponse"
					: "Modifier la Question/Réponse";
			},
		},
		created() {
			this.initialize();
		},
		methods: {
			initialize() {
				this.loading = true;
				services
					.getQuestions()
					.then((response) => {
						this.questions = response.data;
						this.loading = false;
					})
					.catch((err) => {
						console.log(err);
						this.loading = false;
					});
			},
			manageAlert(display, type, message) {
				this.alertShow = display;
				this.alertType = type;
				this.alertMessage = message;
			},
			save() {
				if (this.editedIndex > -1) {
					this.loading = true;
					services
						.updateQuestionAnswer(
							this.questionAnswer,
							this.questionAnswer._id
						)
						.then((response) => {
							console.log(response);
							Object.assign(
								this.questions[this.editedIndex],
								this.questionAnswer
							);
							this.loading = false;
							this.close();
							this.initialize();
						})
						.catch((err) => {
							console.log(err);
							this.loading = false;
						});
				} else {
					this.loading = true;
					services
						.addQuestionAnswer(this.questionAnswer)
						.then((response) => {
							this.close();
							this.loading = false;
							this.manageAlert(true, "success", response.message);
							this.initialize();
						})
						.catch((err) => {
							console.log(err);
							this.loading = false;
							this.manageAlert(true, "error", err.message);
						});
				}
			},
			editItem(item) {
				this.editedIndex = this.questions.indexOf(item);
				this.questionAnswer = Object.assign({}, item);
				this.dialog = true;
			},
			deleteItem(item) {
				this.editedIndex = this.questions.indexOf(item);
				this.questionAnswer = Object.assign({}, item);
				this.dialogDelete = true;
			},
			deleteItemConfirm() {
				this.loading = true;
				services
					.deleteQuestionAnswer(this.questionAnswer._id)
					.then((response) => {
						this.loading = false;
						this.manageAlert(true, "success", response.message);
						this.closeDelete();
						this.initialize();
					})
					.catch((err) => {
						this.loading = false;
						this.manageAlert(true, "error", err.message);
					});
			},
			close() {
				this.dialog = false;
				this.$nextTick(() => {
					this.questionAnswer = Object.assign(
						{},
						this.defaultQuestionAnswer
					);
					this.editedIndex = -1;
				});
			},
			closeDelete() {
				this.dialogDelete = false;
				this.$nextTick(() => {
					this.questionAnswer = Object.assign(
						{},
						this.defaultQuestionAnswer
					);
					this.editedIndex = -1;
				});
			},
		},
	};
</script>

<style></style>
